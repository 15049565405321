<div class="toolbar justify-content-start">
    <h3>Facts</h3>
    <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{domain|t}}</button>
        <div ngbDropdownMenu>
            <button *ngFor="let d of domains" ngbDropdownItem (click)="setDomain(d)">{{d|t}}</button>
        </div>
    </div>
</div>
<div class="prosnconscontainer">
    <table class="param-table">
        <thead>
            <tr>
                <th *ngFor="let h of paramHeader">{{h|t}}</th>
            </tr>
        </thead>
        <tbody *ngIf="dataSub && dataSub.closed">
            <tr *ngFor="let row of maxSubParamCount">
                <td *ngFor="let h of paramHeader">
                    <div *ngIf="params[h].children[row]" class="submenu-item-label-wrapper"
                        [routerLink]="['/gamification/facts-edit']" [queryParams]="params[h].children[row].query">
                        <span class="flex-span"
                            [ngStyle]="{ 'background-color': params[h].color, 'cursor': 'pointer' }">
                            <img src={{params[h].children[row].icon}} class="icon-img" width="24" height="24" />
                            <span>{{params[h].children[row].name|t}}</span>
                            <span class="subparam-count">{{params[h].children[row].facts}}</span>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody>
            <tr *ngIf="dataSub && !dataSub.closed">
                <td colspan="5">
                    <div class="blink text-center py-4">Loading...</div>
                </td>
            </tr>
        </tbody>
    </table>

</div>