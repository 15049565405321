import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, of, Subscription } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../../../services/data.service";
import { catchError, defaultIfEmpty, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AlertPopupComponent } from "../../../_shared/alert-popup/alert-popup.component";
import { AddTipComponent } from '../add-tip/add-tip.component';
import { ConfirmationComponent } from 'src/app/components/_shared/confirmation/confirmation.component';
import { VjsPlayerComponent } from 'src/app/components/_shared/vjs-player/vjs-player.component';
import { ImageViewerComponent } from 'src/app/components/_shared/imageViewer/image-viewer.component';

@Component({
  selector: 'app-tip-edit-item',
  templateUrl: './tip-edit-item.component.html',
  styleUrls: ['./tip-edit-item.component.scss']
})
export class TipEditItemComponent implements OnInit, OnDestroy {

  @Input('item') item: any;
  @Input('index') index: number;

  pageSize = 1;
  p = {};

  pages = [];


  filesToUpload = [];
  videoToUpload = [];
  sub: Subscription;

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    if (this.item) {
      this.pages = this.item.pages || [];
    }
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  edit() {
    const ref = this.modalService.open(AddTipComponent, { size: 'xl' });
    const c = ref.componentInstance as AddTipComponent;
    c.item = this.item;

    ref.result.then(result => {
      if (result == 'saved') {
        // this.updateData();
      }
    }).catch(result => {
      // this.updateData();
    })
  }

  delete() {
    let ref = this.modalService.open(ConfirmationComponent);
    let component = ref.componentInstance as ConfirmationComponent;
    component.title = `Remove tip`;
    component.message = `Are you sure you want to remove tip "${this.item.title}"?`;
    ref.result.then(result => {
      if (result == 'yes') {
        this.dataService.removeTip({ "tip_id": this.item.item.known_id }).subscribe(() => {
          // this.updateData();
        });
      }
    }).catch(() => { });

  }

  clickImage(img) {
    if (img) {
      let ref = this.modalService.open(ImageViewerComponent);
      let component = ref.componentInstance as ImageViewerComponent;
      component.image = img;
    }
  }

  clickVideo() {
    if (this.item) {
      this.dataService.getTipsVideo(this.item.item.known_id, this.item.videos[0].video_id)
        .pipe(
          map(video => ({
            video_id: this.item.videos[0].video_id,
            item: video,
            url: video.url, mode: 'keep',
            thumbnail: '/assets/images/thumbnail.jpg'
          }))
        ).subscribe(res => {
          console.log(res);
          const ref = this.modalService.open(VjsPlayerComponent, { centered: true });
          ref.componentInstance.options = {
            autoplay: true,
            controls: true,
            sources: [{ src: res.url }],
            width: 500
          };
        },
          err => {
            const ref = this.modalService.open(AlertPopupComponent, { centered: true });
            ref.componentInstance.title = 'Error';
            ref.componentInstance.msg = 'Not found this video!';
          });
    }
  }

  pageChanged($event: number) {
    this.p[this.index] = $event;
  }
}
