<div class="toolbar">
  <h3>Phases</h3>
  <button class="btn btn-sm btn-warning text-white mr-0 px-2" (click)="save()">Save</button>

</div>
<div *ngIf="(!sub || sub.closed); else loader" class="phase-container">
  <div class="phase-column" *ngFor="let k1 of getKeys(functionalities)">
    <div
      style="background-color: black;padding: 4px 4px 0px;display: flex;align-items: center;justify-content: center;   font-size: 16px;  font-weight: 600;">
      {{functionalities[k1].title}}
    </div>

    <div style="font-family: 'Open Sans'; margin: 3px 10px;  font-size: 16px;  font-weight: 600;"
      class="custom-control custom-checkbox" *ngFor="let k2 of getKeys(functionalities[k1])">
      <input style="margin-right: 10px;" [checked]="functionalities[k1][k2].status=='enabled'"
        (change)="checkChange($event, k1, k2)" class="custom-control-input" type="checkbox" [id]="k1+k2"
        *ngIf="k2!=='title'">
      <label *ngIf="k2!=='title'" class="d-inline custom-control-label" [for]="k1+k2">{{k2|t}}</label>
    </div>

  </div>

</div>

<ng-template #loader>
  <div *ngIf="sub && !sub.closed" class="text-center blink">Loading...</div>
</ng-template>