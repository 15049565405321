<div class="container">
  <div class="top-nav-bar">
    <div class="top-nav-barmenu">
      <a [ngClass]="{'active': indgroup === 1}" (click)="indgroup=1">Individuals</a>
      <a [ngClass]="{'active': indgroup === 2}" (click)="indgroup=2">Groups</a>
    </div>
  </div>
  <div class="shadowed-block">

    <div class="toolbar">
      <h2>{{indgroup == 1 ? 'Drivers' : 'Groups'}}</h2>
      <div *ngIf="indgroup==1" ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" id="dropdownBasic1" ngbDropdownToggle>{{domain|t}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let d of domains" (click)="setDomain(d)" ngbDropdownItem>{{d|t}}</button>
        </div>
      </div>
      <div class="filler"></div>
      <div *ngIf="indgroup==1">
        <input style="cursor: pointer; margin-right: 5px;" id="invitebox" type="checkbox" [(ngModel)]="inviteonly">
        <label style="cursor: pointer;" for="invitebox"> Employed only</label>

      </div>
      <div class="d-flex justify-content-between">
        <input class="searchfield" [(ngModel)]="searchfield" style=" "
          placeholder="Search for a {{indgroup==1?'driver':'group'}}" />
        <button *ngIf="indgroup==-10" class="btn btn-sm btn-warning text-white mr-0 px-2 nowrap"
          (click)="addDriverPopup()">
          + Invite Driver
        </button>
        <button *ngIf="indgroup==2" class="btn btn-sm btn-warning btn-add text-white mr-0 px-2 nowrap"
          (click)="addGroupPopup()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>


    <div class="tablescroll" style="">
      <table *ngIf="indgroup==1">
        <thead>
          <tr>
            <th style="cursor: pointer" (click)="toggleSort('drivers','first_name', null)">Driver ID <i
                class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th style="cursor: pointer">Short Name <i class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th style="cursor: pointer" (click)="toggleSort('drivers','trucker_type', null)">Transport Type <i
                class="fa fa-sort" aria-hidden="true"></i></th>
            <th style="cursor: pointer" (click)="toggleSort('drivers','psychological_profile',domain)">Behavioural Phase
              <i class="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Group</th>
            <th>Distance (km)</th>
            <th>Time (hrs)</th>
            <th>Trips</th>
            <th>Credits</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filterItems() | paginate: { itemsPerPage: pageSize,currentPage: p}">
            <td>
              <div style="display: flex;">

                <img style="flex:0;width: 50px; height: 50px;border-radius: 50%" src="/assets/images/face-1.png">
                <div style="flex: 1;display: flex;flex-direction: column; justify-content: center;">
                  <div style="cursor: pointer;" [routerLink]="['/results/trips']"
                    [queryParams]="{user_id: item.user_id}">
                    {{ item.system.system_type === 'cardio' && item.system.cardio.short_id || item.system.system_type
                    === 'oseven' &&
                    item.system.oseven.short_id || '' }}
                  </div>
                  <div style="color: #7e7e7e">
                    {{item.system?.system_type && item.system[item.system?.system_type]?.driver_id ? 'ID #' +
                    item.system[item.system?.system_type]?.driver_id : ''}}
                  </div>
                </div>
              </div>
            </td>
            <td style="font-weight: 300">
              {{ item.system.system_type === 'cardio' && item.system.cardio.short_name || item.system.system_type
              === 'oseven' &&
              item.system.oseven.short_name || '' }}
            </td>
            <td style="font-weight: 300">{{item.profile?.trucker_type}}</td>
            <td>{{psychological_profile_type_dict[item.profile.psychological_profile[domain]]}}</td>
            <td>{{item.communities?.length ? item.communities[0].community_name : ''}}</td>
            <td>{{item.stats?.mileage || 0}}</td>
            <td>{{item.duration}}</td>
            <td>{{item.stats?.num_trips || 0}}</td>
            <td>{{item.credits || 0}}</td>
            <td>
              <div>
                <img *ngIf="system !== 'cardio'" class="m-2 pointer" src="assets/images/legend.svg"
                  (click)="levelsModal(item)">
                <img class="m-2 pointer" src="assets/images/winbadge.svg" (click)="badgesModal(item)">
                <img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editProfile(item)">
              </div>
            </td>
          </tr>

          <tr *ngIf="driverSub && !driverSub.closed">
            <td colspan="9" class="text-center blink">Loading...</td>
          </tr>
          <tr *ngIf="driverSub && driverSub.closed && !getPages()">
            <td colspan="9">No search results found to display</td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="indgroup==2">
        <thead>
          <tr>
            <th style="cursor: pointer; width: 300px;" (click)="toggleSort('group','name', null)">Groups <i
                class="fa fa-sort" aria-hidden="true"></i></th>
            <th style="cursor: pointer; width: 300px;">Description</th>
            <th style="cursor: pointer; width: 150px;">Date</th>
            <th style="cursor: pointer; width: 150px;"></th>
          </tr>
        </thead>
        <tbody *ngIf="groupSub && !groupSub.closed">
          <tr>
            <td colspan="9" class="text-center blink">Loading...</td>
          </tr>
        </tbody>
        <tbody *ngIf="groupSub && groupSub.closed && getPages()">
          <tr *ngFor="let item of filterGroups() | paginate: { itemsPerPage: pageSize,currentPage: p} ">
            <td>{{item.name}}</td>
            <td>{{item.description}}</td>
            <td>{{item.created_date | date:"d MMM, yyyy"}}</td>
            <td>
              <img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editProfile(item)">
              <img class="m-2 pointer" src="assets/images/ic_delete.svg" (click)="deleteProfile(item)">
              <!--            <img (click)="onContextMenu($event, item)" style="margin: 10px 10px 10px 20px; cursor: pointer;" src="assets/images/moreicon.svg">-->
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="groupSub && groupSub.closed && !getPages()">
          <tr>
            <td colspan="4">No search results found to display</td>
          </tr>
        </tbody>
      </table>
    </div>
    <context-menu>
      <ng-template *ngIf="indgroup==2" class="context-menu-item" contextMenuItem (execute)="viewProfile($event.item)">
        View Proﬁle
      </ng-template>
      <ng-template class="context-menu-item" contextMenuItem (execute)="editProfile($event.item)">
        Edit
      </ng-template>
      <ng-template *ngIf="indgroup==2" class="context-menu-item" contextMenuItem (execute)="deleteProfile($event.item)">
        Delete
      </ng-template>
    </context-menu>
    <div style="display: flex;align-items: center;justify-content: flex-end;" *ngIf="getPages() > 1">
      <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)"
        directionLinks="true" responsive="false" previousLabel='<' nextLabel='>'
        screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
      </pagination-controls>
      <div style="margin-left:10px; padding-top: 7px;padding-bottom: 16px;">Page {{p}} of {{getPages()}}</div>
    </div>
  </div>


</div>