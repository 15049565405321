import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  map: L.DrawMap;
  menu = [];

  leafletOptions: any;
  tiles: any[] = [];

  ngOnInit(): void {
    this.initMap();
  }

  initMap() {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 19,
        // tileSize: 512,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
      })
    ];

    this.leafletOptions = {
      layers: [
        this.tiles[0]
      ],
      zoom: 9
    };
    this.map = L.map('map', this.leafletOptions).setView(new L.LatLng(50.9327732, 5.338821), 16);
  }

  ngAfterViewInit(): void {
    L.marker(new L.LatLng(50.9327732, 5.338821), {
      icon: L.icon({
        iconUrl: '/assets/images/school-marker.png',
        iconSize: [45, 50],
        iconAnchor: [23, 50]
      }), // here pass the custom marker icon instance,
      draggable: false
    }).addTo(this.map);
  }

}
