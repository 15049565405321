<ng-template #load>
  <div class="text-center blink mt-5">Loading...</div>
</ng-template>

<div class="reportspage">

  <div class="page-header">
    <h2>Scores </h2>
    <div class="toolbar" style="justify-content: flex-start; margin-top: 10px;">

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{ type && typeLabel ? typeLabel : 'Unknown'}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="type = 'general'">General Indicators</button>
          <button ngbDropdownItem (click)="type = 'domain'">Domain Scores</button>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown" *ngIf="type == 'domain'">
        <button class="btn" ngbDropdownToggle>{{domain|t}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let d of domains" (click)="domain = d">{{d|t}}</button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{target && !target.label.includes('null') ? target.label : 'Anonymous
          User'}}</button>
        <div ngbDropdownMenu class="max-height-350 overflow-y-auto">
          <button ngbDropdownItem (click)="setTargetAudience('All Drivers')" class="pl-2"><strong>All
              Drivers</strong></button>
          <button ngbDropdownItem (click)="setTargetAudience('Drivers')" class="pl-2"><strong>Drivers</strong></button>
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)" class="pl-4">
            {{ u.system.system_type === 'cardio' && u.system.cardio.short_id || u.system.system_type === 'oseven' &&
            u.system.oseven.short_id || '' }}
          </button>
          <button ngbDropdownItem (click)="setTargetAudience('Groups')" class="pl-2"><strong>Groups</strong></button>
          <button ngbDropdownItem *ngFor="let group of sortGroup(groups)" (click)="setTargetGroup(group)" class="pl-4">
            {{ group.name }}
          </button>
        </div>
      </div>

      <!--      <div ngbDropdown class="d-inline-block custom_dropdown">-->
      <!--        <button class="btn" id="dropdownTargetAudience" ngbDropdownToggle>{{targetAudience}}</button>-->
      <!--        <div ngbDropdownMenu aria-labelledby="dropdownTargetAudience">-->
      <!--          <button ngbDropdownItem>Day</button>-->
      <!--        </div>-->
      <!--      </div>-->

      <div ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" id="dropdownTimeInterval" ngbDropdownToggle>{{periods[period]['title']}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownTimeInterval">
          <button ngbDropdownItem (click)="setPeriod('day')">{{periods['day']['title']}}</button>
          <button ngbDropdownItem (click)="setPeriod('week')">{{periods['week']['title']}}</button>
          <button ngbDropdownItem (click)="setPeriod('month')">{{periods['month']['title']}}</button>
          <button ngbDropdownItem (click)="setPeriod('3month')">{{periods['3month']['title']}}</button>
          <button ngbDropdownItem (click)="setPeriod('year')">{{periods['year']['title']}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="sidebar">

      <ng-container *ngIf="type == 'general'">
        <div class="menuitem-wrapper">
          <div class="boldtitle titleunderline pointer">General Indicators</div>
          <div class="subparams">
            <div *ngFor="let menuItem of generalMenu" class="pointer w-100 d-inline-block">
              <label class="submenu-item">
                <div class="submenu-item-label-wrapper"
                  [ngStyle]="{ 'background-color': menuItem.color, 'cursor': 'pointer' }"
                  [routerLink]="['/results/scores']" [queryParams]="{id: menuItem.id}" queryParamsHandling="merge">
                  <span class="flex-span" style="">
                    <img src={{menuItem.icon}} class="icon-img" width="24" height="24" />
                    <span>{{menuItem.name|t}} <img *ngIf="menuItem.id == chartId"
                        src='assets/images/rounded-check-mark-white.svg'
                        style="margin-left: 10px; height: 80%;" /></span>

                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="type == 'domain'">
        <ng-container *ngFor="let menuItem of menu">
          <ng-container *ngIf="menuItem.label == domain">

            <div class="menuitem-wrapper">
              <div class="boldtitle titleunderline pointer" [routerLink]="['/results/scores']"
                [queryParams]="{id: menuItem.id}" queryParamsHandling="merge">{{menuItem.name|t}} <img
                  *ngIf="menuItem.id == chartId" src='assets/images/rounded-check-mark-green.svg'
                  style="margin-left: 10px; height: 20px;" /></div>

              <div class="subparams" style="margin-top: 4px; margin-bottom: 5px;"
                *ngFor="let subMenuItem of menuItem.children">
                <label class="submenu-item" *ngIf="subMenuItem.children.length">
                  <div class="submenu-item-label-wrapper"
                    [ngStyle]="{ color: subMenuItem.color, 'cursor': 'pointer', 'border': '1px solid' }"
                    [routerLink]="['/results/scores']" [queryParams]="{id: subMenuItem.id}" queryParamsHandling="merge">
                    <span class="flex-span" style="">
                      <span>{{subMenuItem.name|t}} <img *ngIf="subMenuItem.id == chartId"
                          src='assets/images/rounded-check-mark-green.svg'
                          style="margin-left: 10px; height: 20px;" /></span>
                    </span>
                  </div>
                </label>
                <div class="subparams">
                  <div *ngFor="let sub2menuitem of subMenuItem.children" class="pointer w-100 d-inline-block">
                    <label class="submenu-item">
                      <div class="submenu-item-label-wrapper"
                        [ngStyle]="{ 'background-color': subMenuItem.color, 'cursor': 'pointer' }"
                        [routerLink]="['/results/scores']" [queryParams]="{id: sub2menuitem.id}"
                        queryParamsHandling="merge" [ngClass]="{'disabled': isDisabled(sub2menuitem)}">
                        <span class="flex-span" style="">
                          <img src={{sub2menuitem.icon}} class="icon-img" width="24" height="24" />
                          <span>{{sub2menuitem.name|t}} <img *ngIf="sub2menuitem.id == chartId"
                              src='assets/images/rounded-check-mark-white.svg'
                              style="margin-left: 10px; height: 80%;" /></span>

                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="resultscontainer">
      <ng-container *ngIf="!loader && !errorFound; else load">
        <div class="container-content">
          <div class="chartcontainer" *ngIf="chartCfg">
            <h2><img src="assets/images/chart.svg" style="margin-right: 5px;">{{chartCfg.label|t}}</h2>
            <chart *ngIf="chartData && chartData !='loading'" type="bar" [data]="chartData" [options]="options"></chart>
            <!--      <chart id="chart"  style="height: 300px;" [type]="charttype" [data]="data" [options]="options"></chart>-->
          </div>
          <small style="font-style:italic;margin-left: 25px;" *ngIf="period !== 'day'">* not full calendar
            {{period}}</small>
        </div>
      </ng-container>
    </div>
  </div>



</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="text-left">
    <i style="width: 30px; text-align: center; color: silver" class="fas"
      [ngClass]="{'fa-users': r.prefix == 'Group', 'fa-user': r.prefix == 'User'}"></i>
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
  </div>
</ng-template>