<div class="wrapper" style="">

  <div style="display: flex;flex-direction: column;align-items: center;">
    <div style="margin-bottom: 60px; text-align: center"><img src="/assets/images/iDreamsLogo.svg" class="logo" alt="">
    </div>

    <form action="">
      <div class="signintext">Select a project</div>
      <select name="p" id="" class="form-control custom_dropdown mb-4" [(ngModel)]="project">
        <option *ngFor="let p of projects" [ngValue]="p" >{{p.project_name}}</option>
      </select>
      <div class="d-flex justify-content-around w-100">
        <button
          (click)="chooseProject()"
          class="btn btn-warning text-white ld-ext-right"
          [ngClass]="{'running': sub && !sub.closed}"
          [disabled]="sub && !sub.closed">
          SUBMIT
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button (click)="cancel()" class="btn cancel-btn text-white">CANCEL</button>
      </div>
    </form>
  </div>

</div>
