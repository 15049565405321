import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbTypeaheadSelectItemEvent
} from "@ng-bootstrap/ng-bootstrap";
import { Route, Segment } from '../../../models/segment';
import * as moment from 'moment';
import 'moment-duration-format';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import 'hls.js';
import { EventEmitter } from '@angular/core';

declare let Hls;

@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.scss']
})
export class TripMapComponent implements OnInit, OnChanges {

  @Input() selected: any;
  @Input() flattenLegend = {};
  @Input() filterLegends = [];
  @Input() status = [];

  @Output() mapClick: EventEmitter<any> = new EventEmitter();

  map: L.DrawMap;
  menu = [];

  leafletOptions: any;
  tiles: any[] = [];

  isMapOpen = false;

  trips: any[] = [];
  legends = {};

  layergroup: L.LayerGroup;
  routing: L.Routing.Control;
  popupShown: boolean = false;
  markerPopup: any;
  tripRoute: Route;
  highlight: L.Polyline;

  statusAvailableFields = ['steering', 'acceleration', 'deceleration', 'speeding', 'tailgating'];

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filterLegends' in changes && changes["filterLegends"].previousValue !== undefined) {
      this.addEvents();
    }

    if ('status' in changes && changes["status"].previousValue !== undefined) {
      this.addEvents();
    }
  }

  initMap() {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 19,
        // tileSize: 512,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
      })
    ];

    this.leafletOptions = {
      layers: [
        this.tiles[0]
      ],
      zoom: 9
    };
    this.map = L.map('map', this.leafletOptions).setView(new L.LatLng(
      this.selected['gps_trace'].lat[0],
      this.selected['gps_trace'].lon[0],
    ), 14);

    this.newRouting(this.selected['gps_trace'].lat.map((e, index) => {
      return L.latLng(e, this.selected['gps_trace'].lon[index])
    }));

    this.map.on('popupopen', function (e) {
      var video = document.getElementById("video") as HTMLMediaElement;
      var videoSrcInHls = e['popup']._source.video;

      if (videoSrcInHls && Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(videoSrcInHls.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          // video.play();
        });
        video.addEventListener('loadedmetadata', () => {
          video.currentTime = videoSrcInHls.delta || 0;
        });
      }
    });

    this.map.on('click', (e) => {
      this.mapClick.emit(null);
    });
  }

  removeEvents() {
    if (this.legends) {
      Object.keys(this.legends).forEach(key => {
        this.legends[key].entities.forEach(entity => {
          this.map.removeLayer(entity);
        })
      });
    }
  }

  removeHighlights() {
    if (this.highlight) {
      this.highlight.removeFrom(this.map);
      this.highlight = null;
    }
  }

  addEvents() {
    this.removeEvents();
    this.removeHighlights();
    Object.keys(this.flattenLegend).forEach(key => {
      if (this.filterLegends.length && this.filterLegends.indexOf(key) > -1) {
        this.addMarker(key);
      }
    })
    // if ( this.filterLegends.length) this.addHighlights();
  }

  // addHighlights() {
  //   this.tripRoute.segments.forEach(seg => {
  //     if ( seg.visible ) {
  //       if ( seg.appliedKeys.length ) {
  //         seg.poly.addTo(this.map);
  //       } 
  //       else {
  //         seg.visible = false;
  //       } 
  //     }
  //   })
  // }

  addMarker(key) {
    const param = key.split('/')[1],
      subparam = key.split('/')[2];

    this.legends[key] = {
      ...this.flattenLegend[key],
      entities: []
    };

    let items = this.legends[key].entities;
    console.log(this.legends[key].info.icon);

    (this.selected.events || []).filter(
      e => e.param == param && e.subparam == subparam
    ).forEach(event => {
      let canVisible = false;
      if (this.statusAvailableFields.indexOf(event.subparam) > -1) {
        this.status.forEach(st => {
          if (event.subparam !== 'tailgating') {
            if (event.status && st === event.status) {
              canVisible = true;
            }
          }
          else {
            if (st === 'high') {
              if (event.level && event.level === 3) {
                canVisible = true;
              }
            }
            else if (st === 'medium') {
              if (event.level && event.level === 2) {
                canVisible = true;
              }
            }
          }
        });
      }
      else {
        canVisible = true;
      }

      if (event.gps_point && canVisible) {
        let marker = this.marker(event, this.legends[key].info);
        marker.addTo(this.map);
        items.push(marker);
        if (event.duration) {

        }
      }
    });
  }

  newRouting(trace_points: L.LatLng[]) {

    var polyline = L.polyline(trace_points, { color: 'grey' }).addTo(this.map);
    this.map.fitBounds(polyline.getBounds());

    // Add start, end markers
    L.marker(trace_points[0], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_start.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50]
      }), // here pass the custom marker icon instance,
      draggable: false
    }).addTo(this.map);

    L.marker(trace_points[trace_points.length - 1], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_end.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50]
      }), // here pass the custom marker icon instance,
      draggable: false
    }).addTo(this.map);

    this.addEvents();
    return this.routing;
  }

  extendMarker(i, waypoint, n) {
    if (i == 0 || i == n - 1)
      return null;

    return L.marker(waypoint.latLng, { icon: L.icon({ iconUrl: 'assets/marker/dot.png', iconSize: [19, 19] }), draggable: true });
  }

  private marker(event, iconInfo) {

    const myCustomColour = '#583470'

    const markerHtmlStyles = `
      background: url('${iconInfo.icon}') no-repeat;
      background-size: calc(100% - 5px);
      background-position-x: 2.5px;
      background-position-y: 2.5px;`;

    let icon = L.divIcon({
      className: 'custom-div-icon',
      html: `<div style='background-color: ${iconInfo.color ? iconInfo.color : myCustomColour};' class='marker-pin'></div>
      <span style="${markerHtmlStyles}" />`,
      iconSize: [40, 50],
      iconAnchor: [20, 50]
    });
    let marker = L.marker(L.latLng(event.gps_point.lat, event.gps_point.lon), {
      icon: icon,
    });

    marker.on('click', e => {
      this.removeHighlights();
      marker['video'] = event.video;
      let fields_to_show = '';
      if (event.fields_to_show) {
        const shows = Object.keys(event.fields_to_show).map(key => `<div class="leaflet-popup-body-show"><label>${key}:</label><span>${event.fields_to_show[key]}</span></div>`);
        fields_to_show = shows.join('');
      }
      let video = '';
      if (event.video) {
        video = `<video id="video" style="margin:auto;" width="320" height="240" controls>
        </video>`;
      }
      if (event.fields_to_show || event.video) {
        if (!marker.getPopup()) {
          marker.bindPopup(`
            <div class="leaflet-popup-content-header"><strong>${event.subparam}</strong></div>
            <div class="leaflet-popup-content-body">
              ${fields_to_show}
              ${video}
            </div>`).openPopup();
        }
        else {
          marker.bindPopup(`
            <div class="leaflet-popup-content-header"><strong>${event.subparam}</strong></div>
            <div class="leaflet-popup-content-body">
              ${fields_to_show}
              ${video}
            </div>`);
        }
      }
      if (event.duration && event.gps_trace) {

        this.highlight = L.polyline(event.gps_trace.lat.map((e, index) => {
          return L.latLng(e, event.gps_trace.lon[index])
        }), { color: 'red' });
        this.highlight.addTo(this.map);
      }
    });

    return marker;
  }

}
