<div class="reportspage">

  <div class="sidebar">
    <h2>Reports </h2>
  </div>

  <div class="resultscontainer">

    <div class="toolbar" style="justify-content: flex-start; margin-top: 10px; padding-bottom: 0;">
      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateReport()">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Company Report</button>
        </div>
      </div>

    </div>

    <div class="toolbar" style="justify-content: flex-start; padding-top: 0;">
      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">

        <button class="btn" ngbDropdownToggle>{{target && !target.label.includes('null') ? target.label : ''}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)">
            {{ u.system.system_type === 'cardio' && u.system.cardio.short_id || u.system.system_type === 'oseven' &&
            u.system.oseven.short_id || '' }}
          </button>
        </div>
      </div>


      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateReport(true)">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Report</button>
        </div>
      </div>

    </div>

  </div>
</div>