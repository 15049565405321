import {Component, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  tab = 0;
  items = [
    {
      routerLink: 'trips',
      title: 'Trips'
    },
    {
        routerLink: 'scores',
        title: 'Scores'
    },
    {
      routerLink: 'reports',
      title: 'Reports'
  }
  ];


  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'result-page');
  }

  setTab(number: number) {
    this.tab = number;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'result-page');
  }
}
