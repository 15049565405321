import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public isMenuCollapsed = true;

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  getProjectlogo() {
    const project = JSON.parse(localStorage.getItem('project'));
    const { configuration } = project;

    return configuration.project_image ? configuration.project_image : '/assets/images/idreamLogo.svg';
  }


  getUserName() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.email;
  }


}
