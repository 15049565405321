<form class="popupcontainer" (submit)="submit()">
    <div class="closecross" (click)="closeModal()">✕</div>
    <h2 class="ml-0"> {{item ? 'Edit' : 'Add'}} {{type|t}} for {{subparam_label|t}}</h2>
    <div>

    </div>
    <div class="mt-2 form-row">
        <!-- <div class="col-md-4">
        <textarea class="form-control" rows="4" [(ngModel)]="title" name="title" placeholder="Title"></textarea>
    </div> -->

        <div class="col-md-4">
            <textarea class="form-control" rows="4" [(ngModel)]="description" name="description"
                placeholder="Describe the {{type}} you wish to add "></textarea>
        </div>

        <div class="col-md-4">
            <div appDragDropFileUpload class="dropboxcontainer"
                [style]="filesToUpload.length==0 ? 'height: 150px;':'min-height: 150px;'"
                (fileDropped)="addFilesToUpload($event)" (click)="fileField.click()">
                <div *ngIf="filesToUpload.length>0" class="dropimagelist">
                    <ng-container *ngFor="let file of filesToUpload; let index=index;">
                        <div class="dropimagewrapper" *ngIf="file.mode != 'del'">
                            <img [src]="file.url|sanitizeUrl" class="dropimage">
                            <span appClickStopPropagation (click)="remove(index)" class="redclosicon">✕</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="filesToUpload.length==0" class="dropcaption">Upload Image
                </div>
                <!--suppress TypeScriptUnresolvedVariable -->
                <input type="file" name="avatars" #fileField (change)="addFilesToUpload($event.target.files)" hidden
                    multiple>
            </div>
        </div>

        <div class="col-md-4">
            <div appDragDropFileUpload class="dropboxcontainer"
                [style]="videoToUpload.length==0 ? 'height: 150px;':'min-height: 150px;'"
                (fileDropped)="addVideoToUpload($event)" (click)="videofield.click()">
                <div *ngIf="videoToUpload.length>0" class="dropimagelist">
                    <ng-container *ngFor="let file of videoToUpload; let index=index;">
                        <div class="dropimagewrapper" *ngIf="file.mode != 'del'">
                            <img [src]="file.thumbnail" class="dropimage">
                            <span appClickStopPropagation (click)="removeVideo(index)" class="redclosicon">✕</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="videoToUpload.length==0" class="dropcaption">Upload Video
                </div>
                <!--suppress TypeScriptUnresolvedVariable -->
                <input type="file" name="videos" #videofield accept="video/mp4,video/x-m4v,video/*"
                    (change)="addVideoToUpload($event.target.files)" hidden multiple>
            </div>
        </div>
    </div>
    <div class="horizontaldivider"></div>
    <div style="display: flex;justify-content: center;">
        <button class="btn btn-warning text-white w-25" [disabled]="(sub && !sub.closed) || !valid">SAVE</button>
    </div>

</form>