<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="text-center mb-4">{{message}}</div>

  <div class="text-center">
    <button type="button" class="btn btn-danger w-25 mr-4" (click)="no()">NO</button>
    <button type="button" class="btn btn-primary w-25 ml-4" (click)="yes()">YES</button>
  </div>

</div>
