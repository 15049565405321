import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss']
})
export class GamificationComponent implements OnInit {
  tab = 0;
  items = [
    {
      routerLink: 'prosncons',
      title: 'Pros & Cons'
    },
    {
      routerLink: 'tips',
      title: 'Coping Tips'
    },
    {
      routerLink: 'facts',
      title: 'Facts'
    },
    {
      routerLink: 'goals',
      title: 'Goals'
    },
    {
      routerLink: 'credits',
      title: 'Credits'
    },
    {
      routerLink: 'shop',
      title: 'Shop'
    },
    {
      routerLink: 'survey',
      title: 'Survey'
    },
    {
      routerLink: 'phases',
      title: 'Phases'
    },
  ];


  constructor() {
  }

  ngOnInit(): void {
  }

  setTab(number: number) {
    this.tab = number;
  }
}
