<div class="popupcontainer">

  <div class="modal-header">
    <h4 class="modal-title">{{create ? 'Create' : 'Edit'}} Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-5" style="display: flex;flex-direction: row;">

    <div *ngIf="!imageFile" class="text-secondary my-2 " style="flex: 1; padding-right: 15px;">
      <ngx-file-drop dropZoneClassName="bg-light w-100 h-100 py-2 text-center mr-1 pointer"
        (onFileDrop)="newImage($event)" dropZoneLabel="" [showBrowseBtn]="true"
        browseBtnLabel="Change/Upload Image"
        browseBtnClassName="btn btn-outline-primary" [multiple]="false"></ngx-file-drop>
      <div class="img-drop-error-message" *ngIf="error">
        *&nbsp;{{error}}
      </div>
    </div>
    <div *ngIf="imageFile" class="text-secondary my-2 image-file-wrapper" style="flex: 1; padding-right: 15px;">
      <img [src]="imageFile['url']" style="width: 100%;height: auto;">
      <span (click)="removeImage()" aria-hidden="true" class="remove-img">&times;</span>
    </div>

    <div style="display: flex;flex-direction: column; flex:1;">
      <div class="form-group">
        <input class="form-control" [disabled]="item?.is_general_product" [(ngModel)]="title"
          placeholder="Product Name">
      </div>
      <div class="form-group">
        <div style="display: flex;">
          <input class="form-control" style="width: auto;" type="number" [(ngModel)]="credits" placeholder="Price">
          &nbsp;<label class="credits">Credits</label>
        </div>
      </div>
      <div class="form-group">
        <textarea class="form-control" [disabled]="item?.is_general_product" [(ngModel)]="description"
          placeholder="Description" rows="5"></textarea>
      </div>
      <div ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" style="width: 100%;" ngbDropdownToggle>{{tabs[selectedCat]['display_name']}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let t of tabs; let i= index" (click)="setCat(i)"
            ngbDropdownItem>{{t['display_name']}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" style="margin-top: 15px; margin-right: 15px;">
    <button class="btn btn-warning text-white font-weight-bold px-4" (click)="submitProduct()"
      [disabled]="error">{{create ? 'Create' : 'Edit'}}</button>
  </div>
</div>