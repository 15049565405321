import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfig} from '../app.config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.config.getEndpoint('oauth/auth'),
      {
        grant_type: 'password',
        username: username,
        password: password,
        client_id: 1
      }
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  isLoggedIn(): boolean {
    let user = localStorage.getItem("currentUser");
    if(user) {
      return true;
    }
    return false;
  }
}
