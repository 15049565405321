<div style="display: flex; flex:1;flex-direction: column;width: 100%;">
  <h2>Contact us</h2>
  <div
    style="flex: 1; display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start; position: relative">
    <div
      style="position: relative;background-color:white; z-index: 999; border-radius: 20px;box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.75); padding: 20px;margin-left: 30px;margin-top: 50px;">
      The i-Dreams project is cordinated by:<br>
      <img src="/assets/images/imob.svg" alt="" class="my-3"><br>
      <b>Hasselt University<br>
        Transportation Research Institute</b><br>
      Wetenschapspark 5<br>
      3590 Diepenbeek<br>
      Belgium<br>
      <br>
      Tel. +32 (0)11/26.91.02<br>
      Email: <a href="mailto:idreams.admin@uhasselt.be">idreams.admin@uhasselt.be</a>
    </div>
    <div id="map" style="position:absolute;top:0;bottom: 0;left: 0;right: 0;"></div>
  </div>


  <div style="padding: 0px 20px 0px 0; background-color: black;color: white;" class="d-flex align-items-center">
    <img src="/assets/images/eucomission.svg" alt="" class="mr-4">
    <span class="flex-grow-1">The project has received funding from the European Union’s Horizon 2020 research and
      innovation programme under grant agreement No 814761</span>
  </div>
</div>