import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditGoalsModalComponent } from "../edit-goals-modal/edit-goals-modal.component";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../../../services/data.service";
import { Subscription } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";

@Component({
  selector: 'app-param-goals',
  templateUrl: './param-goals.component.html',
  styleUrls: ['./param-goals.component.scss']
})
export class ParamGoalsComponent implements OnInit, OnDestroy {

  goals: any[] = [];

  domain: string;
  param: string;
  subparam: string;
  subparam_label: string;

  batchId: number = null;
  startGoalsSub: Subscription;
  goalsSub: Subscription;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {

      this.domain = params.domain;
      this.param = params.param;
      this.subparam = params.subparam;
      this.subparam_label = this.dataService.project['configuration']['schema'][this.domain]?.params[this.param].subparams[this.subparam]['name'];

      this.loadGoals();
    });

    // this.editGoal();
  }

  ngOnDestroy(): void {
    this.goalsSub && this.goalsSub.unsubscribe();
    this.startGoalsSub && this.startGoalsSub.unsubscribe();
  }

  loadGoals() {
    this.batchId = null;
    this.goalsSub = this.getGoals().subscribe();
  }

  getGoals() {
    return this.dataService.getGoalsBatch(this.subparam).pipe(
      tap(data => {
        if (data.length) {
          data[0].goals.sort((a,b) => a.goal_batch_rank - b.goal_batch_rank || a.goal_batch_order - b.goal_batch_order);
          this.batchId = data[0].id;
        }
        console.log(data);
        this.goals = data;
      })
    );

  }

  get started() {
    return (this.goals.length && this.goals[0].started);
  }

  editGoal() {
    let ref = this.modalService.open(EditGoalsModalComponent, {size: 'lg'});
    ref.componentInstance.goals = this.goals;
    ref.componentInstance.domain = this.domain;
    ref.componentInstance.param = this.param;
    ref.componentInstance.subparam = this.subparam;
    ref.componentInstance.subparam_label = this.subparam_label;
    ref.result.then(()=>{
      this.loadGoals();
    }, ()=>{});
  }

  startGoals() {
    if (this.started) return;

    if (this.batchId) {
      this.startGoalsSub = this.dataService.startGoals(this.batchId).pipe(
        mergeMap(() => this.getGoals())
      ).subscribe()
    }
  }
}
