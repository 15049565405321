import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data.service';
import {Subscription} from 'rxjs';
import {StorageService} from '../../../services/storage.service';
import {TreeviewComponent, TreeviewItem} from 'ngx-treeview';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss']
})
export class AddDriverComponent implements OnInit {

  sub: Subscription;
  fname: any;
  lname: any;
  email: any;
  phone: any;
  address1: any;
  address2: any;
  city: any;
  postal: any;
  country: any;

  edit = true;

  user_id: any;
  transport_type: any;
  behaviour_phase: any;
  group: any;

  item: any;
  domains: string[];
  domain: any;
  safeId = '';
  psychological_profile_type_dict = {};
  trucker_type_dict = {};
  psychological_profile = ['none', 'precontemplation', 'contemplation', 'preparation', 'action', 'maintenance'];
  transport_profile = ['none', 'construction', 'distribution', 'heavy_haulage', 'long_haul_gt_300', 'long_haul_lt_300'];
​

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private storage: StorageService,
  ) {

  }

  ngOnInit(): void {
    console.log(this.storage.get('project').configuration);


    this.psychological_profile_type_dict = this.storage.get('project').configuration.psychological_profile_type_dict;
    this.trucker_type_dict = this.storage.get('project').configuration.trucker_type_dict;

    this.user_id = this.item.user_id;
    this.safeId = this.item['safe-t-id'];
    this.fname = this.item.first_name;
    this.lname = this.item.last_name;
    this.email = this.item.email;
    this.phone = this.item.phone_number;
    this.address1 = this.item.address.street;
    this.address2 = this.item.address.number;
    this.city = this.item.address.city;
    this.postal = this.item.address.postal_code;
    this.country = this.item.address.country;
    this.transport_type = this.item.profile.trucker_type;
    this.behaviour_phase = this.item.profile.psychological_profile[this.domain];
    this.group = this.item.communities.length ? this.item.communities[0].community_name : null;


  }

  getTransportTypeName(ttcode){
    return this.trucker_type_dict[ttcode]?this.trucker_type_dict[ttcode]:'none';
  }
  getPsychoProfName(ppcode){
    return this.psychological_profile_type_dict[ppcode]?this.psychological_profile_type_dict[ppcode]:'none';
  }


  saveDriver() {

    this.item.profile.psychological_profile[this.domain]=this.behaviour_phase;

    this.sub = this.dataService.saveUser(this.item.user_id, this.address1, this.address2, this.city, this.country, this.postal, this.phone).subscribe(data => {
      console.log(data);

      this.dataService.saveUserProfile(this.item.user_id,this.transport_type,this.item.profile.psychological_profile).subscribe(((data2) => {
        console.log(data2);
        this.activeModal.close();
      }));
    })
  }
}
