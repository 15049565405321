import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Subscription } from 'rxjs';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: boolean = false;
  model: { username: string, password: string } = {username: '', password: ''};
  projects: any[];
  logged: boolean = false;
  sub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  login() {
    this.sub = this.authService.login(this.model.username, this.model.password)
      .subscribe(
        (user) => {
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.router.navigate(['/choose-project']);
          }
        },
        error => {
          if(error.status == 403) {
            console.log("error code: ", error.status);
          } else {
            this.error = true;
          }
        }
      );
  }


}
