<div class="popupcontainer">

  <div class="modal-header">
    <h4 class="modal-title">{{ edit ? 'Driver Details' : 'Add Driver'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-5">

      <div class="driver-details-wrapper" style="">
        <div *ngIf="edit" class="driver-details-column flex-1" style="flex: 1;">
          <div style="width: 100%;margin-top: 10px;" class="text-bold">ID</div>

          <div class="group-title"></div>
          <input disabled [(ngModel)]="user_id" class="form-control" placeholder="User Id">
          <div class="group-title"></div>

          <div ngbDropdown class="d-inline-block custom_dropdown" style="width: 100%;">
            <button class="btn" ngbDropdownToggle style="width: 100%;">{{getTransportTypeName(transport_type)}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let p of transport_profile; let i= index" (click)="transport_type=p" ngbDropdownItem>{{getTransportTypeName(p)}}</button>
            </div>
          </div>
          <div class="group-title"></div>
          <div ngbDropdown class="d-inline-block custom_dropdown" style="width: 100%;">
            <button class="btn" ngbDropdownToggle style="width: 100%;">{{getPsychoProfName(behaviour_phase)}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let p of psychological_profile; let i= index" (click)="behaviour_phase=p" ngbDropdownItem>{{getPsychoProfName(p)}}</button>
            </div>
          </div>
          <div class="group-title"></div>
          <input disabled [(ngModel)]="group" class="form-control" placeholder="Group">
        </div>
        <div class="driver-details-column flex-2" style="flex: 2;">
          <div class="driver-contact-details text-bold" style="width: 100%;margin-top: 10px;">CONTACT DETAILS</div>
          <div class="group-title"></div>
          <input [(ngModel)]="fname" class="form-control" placeholder="First Name">
          <div class="group-title"></div>
          <input [(ngModel)]="lname" class="form-control" placeholder="Last Name">
          <div style="display: flex;">

            <div style="display: flex;flex-direction: column; padding-right: 15px;">
              <div class="group-title"></div>
              <input [(ngModel)]="address1" class="form-control" placeholder="Address Line 1">
            </div>
            <div style="display: flex;flex-direction: column; padding-left: 15px;">
              <div class="group-title"></div>
              <input [(ngModel)]="address2" class="form-control" placeholder="Address Line 2">
            </div>
          </div>

          <div style="display: flex;">
            <div style="display: flex;flex-direction: column; padding-right: 15px;">

              <div class="group-title"></div>
              <input [(ngModel)]="city" class="form-control" placeholder="City">
            </div>
            <div style="display: flex;flex-direction: column; padding-left: 15px;">
              <div class="group-title"></div>
              <input [(ngModel)]="postal" class="form-control" placeholder="Postal Code">
            </div>
          </div>
          <div class="group-title"></div>
          <input [(ngModel)]="country" class="form-control" placeholder="Country">
          <div class="group-title"></div>
          <input disabled [(ngModel)]="email" class="form-control" placeholder="Email">
          <div class="group-title"></div>
          <input [(ngModel)]="phone" class="form-control" placeholder="Phone No">
        </div>
        <div class="driver-details-column flex-3" style="flex: 2;">
          <div class="text-bold" style="width: 100%;margin-top: 10px;">LOGIN DETAILS</div>
          <div class="group-title"></div>
          <input [value]="safeId" class="form-control" disabled placeholder="Id">
          <div class="group-title"></div>
          <input class="form-control" disabled value="************" />
        </div>
      </div>
      <div class="text-right" style="margin-top: 15px; margin-right: 15px;">
        <button class="btn btn-warning text-white font-weight-bold px-4 ld-ext-right"
        [ngClass]="{'running': sub && !sub.closed}"
        [disabled]="sub && !sub.closed"
         (click)="saveDriver()">SAVE
        <div class="ld ld-ring ld-spin"></div>
        </button>
      </div>
  </div>

</div>
