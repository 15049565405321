<div class="container-fluid">
  <div class="toolbar">
    <h2>FORUM</h2>
    <div class="blink text-center" *ngIf="(sub && !sub.closed) && (messages.length)">Loading...</div>
    <div class="d-flex">
      <div ngbDropdown class="mr-1">
        <button ngbDropdownToggle class="btn btn-light">{{ filters.own ? 'My messages' : 'All messages' }}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="filters.own=true;">My messages</button>
          <button ngbDropdownItem (click)="filters.own=false;">All messages</button>
        </div>
      </div>

      <div ngbDropdown class="mr-1">
        <button ngbDropdownToggle class="btn btn-light">{{type | titlecase}}</button>
        <div ngbDropdownMenu>
          <button *ngFor="let cat of categories" ngbDropdownItem (click)="setType(cat.value)">{{cat.value | titlecase}}</button>
        </div>
      </div>

      <div ngbDropdown *ngIf="type==='user'">
        <button ngbDropdownToggle class="btn btn-light">
          {{ filters.user.first_name + filters.user.last_name != '' ? filters.user.first_name + ' ' + filters.user.last_name : 'Anonymous User' }}
        </button>
        <div ngbDropdownMenu>
          <button *ngFor="let user of availableUsers()" ngbDropdownItem (click)="setFilter('user', user)">
            {{ user.first_name + user.last_name != '' ? user.first_name + ' ' + user.last_name : 'Anonymous User' }}
          </button>
        </div>
      </div>

      <div ngbDropdown *ngIf="type==='group'">
        <button ngbDropdownToggle class="btn btn-light">{{filters.group.name}}</button>
        <div ngbDropdownMenu>
          <button *ngFor="let group of groups" ngbDropdownItem (click)="setFilter('group', group)">{{group.name}}</button>
        </div>
      </div>
    </div>
    <div class="flex-grow-1"></div>
  </div>

  <div class="row">

    <div class="col-12 col-md-6 order-1 order-md-0">

      <div class="message-list">

        <ng-container *ngIf="(!sub || sub.closed) || messages.length; else loader">
          <div class="message-list-item" *ngFor="let msg of filterMessages()">
            
            <button type="button" class="close" aria-label="Close" (click)="deleteMessage(msg.message_id)">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="d-block d-lg-flex align-items-center">

              <div class="d-flex align-items-center flex-grow-1 order-1 flex-wrap mb-2">
                <div class="avatar-tiny mr-2" style="background-image: url('/assets/images/face-1.png')">    </div>
                <div class="mb-0 font-weight-bold flex-grow-1">{{users[msg.sender_id]|sender}}</div>
                <div class="message-timestamp text-secondary">
                  {{(msg.created_date+'Z')|date:'medium'}}
                </div>
              </div>
            </div>
            <h4 class="ml-0 my-0 order-0">{{msg.title}}</h4>


            <div>
              {{msg.body}}

              ... <a [routerLink]="['/forum', 'message', msg.message_id]" class="text-warning text-nowrap">read more</a>
            </div>
            <div class="msg-thumb-container" lightbox-group>
              <img *ngFor="let img of msg.images" [src]="[msg.message_id, img.image_id]|authImage|async" lightbox class="msg-thumb-tiny">
            </div>

            <div class="d-flex mt-3 justify-content-between">

<!--              <div class="message-counter ml-3">-->
<!--                <i class="fas fa-eye"></i> 64-->
<!--              </div>-->
              <div class="message-counter" *ngIf="msg">
                <i class="fas fa-heart pointer" [ngClass]="{'gray500': !(msg && (msg|isLiked))}" (click)="like(msg)"></i> {{msg.likes.length}}
                Like{{msg.likes.length == 1 ? '' : 's' }}
              </div>
              <div class="message-counter" *ngIf="msg">
                <i class="fas fa-comment pointer gray500" (click)="comment(msg)"></i> {{msg.replies.length}} Comment{{msg.replies.length == 1 ? '' : 's'}}
              </div>

            </div>
          </div>
        </ng-container>

        <ng-template #loader>
          <div class="blink text-center">Loading...</div>
        </ng-template>

        <div class="text-center" *ngIf="(!sub || sub.closed) && (!messages.length || !filterMessages().length)">No messages to display</div>
      </div>


    </div>

    <div class="col-12 col-md-6 order-0 order-md-1">

      <form [formGroup]="newMessage" (ngSubmit)="postMessage()" class="mb-4 message-list-item">
        <div class="d-flex mb-1 align-items-center">
          <span style="width: 100px">From</span>
          <input type="text" class="form-control flex-grow-1 w-25" value="Me" disabled>
        </div>

        <div class="d-flex mb-1 align-items-center">
          <span style="width: 100px">To</span>

          <div ngbDropdown class="mr-1">
            <button type="button" ngbDropdownToggle class="btn btn-light" (click)="$event.preventDefault();">{{recipientType | titlecase}}</button>
            <div ngbDropdownMenu>
              <button *ngFor="let cat of categories" ngbDropdownItem (click)="setRecipientType($event, cat.value)">{{cat.value | titlecase}}</button>
            </div>
          </div>
    
          <div ngbDropdown *ngIf="recipientType==='user'">
            <button type="button" ngbDropdownToggle class="btn btn-light">
              {{ recipient.user.first_name + recipient.user.last_name != '' ? recipient.user.first_name + ' ' + recipient.user.last_name : 'Anonymous User' }}
            </button>
            <div ngbDropdownMenu>
              <button *ngFor="let user of availableUsers()" ngbDropdownItem (click)="setRecipient($event, 'user', user)">
                {{ user.first_name + user.last_name != '' ? user.first_name + ' ' + user.last_name : 'Anonymous User' }}
              </button>
            </div>
          </div>
    
          <div ngbDropdown *ngIf="recipientType==='group'">
            <button type="button" ngbDropdownToggle class="btn btn-light">{{recipient.group.name}}</button>
            <div ngbDropdownMenu>
              <button *ngFor="let group of groups" ngbDropdownItem (click)="setRecipient($event, 'group', group)">{{group.name}}</button>
            </div>
          </div>
        </div>

        <input type="text" name="subject" class="form-control flex-grow-1 mb-1" placeholder="Subject" formControlName="subject">
        <textarea class="form-control" name="message" rows="5" placeholder="Message text" formControlName="message"></textarea>

        <div class="row my-2">
          <div class="col-3 mb-1" *ngFor="let img of formImages">
            <div class="thumb" [style.background-image]="img['url']"></div>
            <div class="thumb-del" (click)="newMsgDelFile(img)"><i class="fas fa-times-circle"></i></div>
          </div>
        </div>


        <div class="text-secondary my-2 pointer">
          <ngx-file-drop dropZoneClassName="bg-light w-100 py-2 text-center mr-1"
                         (onFileDrop)="newMsgAddFile($event)"
                         dropZoneLabel="Drop images here..."
                         [showBrowseBtn]="true"
                         browseBtnClassName="ml-4 btn btn-outline-primary"
          ></ngx-file-drop>
<!--          <div class="bg-light w-50 py-4 text-center ml-1"><i class="fas fa-upload"></i> Upload video</div>-->
        </div>

        <div class="text-right">
          <button class="btn btn-warning text-white font-weight-bold px-4" [disabled]="sendDisabled">SEND</button>
        </div>


      </form>
    </div>
  </div>
</div>

<ng-template #rt let-r="result" let-t="term">
    <i style="width: 30px; text-align: center; color: silver" class="fas" [ngClass]="{'fa-users': r.prefix == 'Group', 'fa-user': r.prefix == 'User'}"></i>
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
</ng-template>
