import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { DataService } from "../../../services/data.service";

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {
  private system: string;

  domain = '';
  maxSubParamLength = 0;

  menu: {[key: string]: {}} = {};
  menuHeader = [];

  goalsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;
    const schema = this.dataService.project.configuration.schema;

    this.route.queryParams.subscribe(query => {
      if (query.domain) {
        this.setDomain(query.domain);
      }
    });

    if (schema.eco_efficiency) {

      this.domain = this.domain || 'eco_efficiency';

      this.menu['eco_efficiency'] = [];
      const eco = this.menu['eco_efficiency'];

      this.goalsSub = this.dataService.getGoals(this.domain).subscribe(
        data => {
          for (const param in schema.eco_efficiency[this.system].params) {
            const paramMenu = this.menuItem(param, param, 'eco_efficiency', param, null, null, schema.eco_efficiency[this.system].params[param].color);
            eco[param] = paramMenu;
    
            if ( this.maxSubParamLength < Object.keys(schema.eco_efficiency[this.system].params[param].subparams).length ) {
                this.maxSubParamLength = Object.keys(schema.eco_efficiency[this.system].params[param].subparams).length;
            }
    
            Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
              const count = data.filter(e => e.param_type === param && e.subparam_type === subparam).length;
              paramMenu.children.push(this.menuItem(subparam, schema.eco_efficiency[this.system].params[param].subparams[subparam].name, 'eco_efficiency', param, subparam, schema.eco_efficiency[this.system].params[param].subparams[subparam].icon, '#f5a623', count));
            });
          }
        }
      );
    }

    if (schema.safety) {

      this.domain = this.domain || 'safety';

      this.menu['safety'] = [];
      const safety = this.menu['safety'];

      this.goalsSub = this.dataService.getGoals(this.domain).subscribe(
        data => {
          for (const param in schema.safety.params) {
            const paramMenu = this.menuItem(param, param, 'safety', param, null, null, schema.safety.params[param].color);
            safety[param] = paramMenu;
            if ( this.maxSubParamLength < Object.keys(schema.safety.params[param].subparams).length ) {
              this.maxSubParamLength = Object.keys(schema.safety.params[param].subparams).length;
            }
    
            Object.keys(schema.safety.params[param].subparams).map(subparam => {
              const count = data.filter(e => e.param_type === param && e.subparam_type === subparam).length;
              paramMenu.children.push(this.menuItem(subparam, schema.safety.params[param].subparams[subparam].name, 'safety', param, subparam, schema.safety.params[param].subparams[subparam].icon, '#f5a623', count));
            });
          }
        }
      );
    }

    if (schema.functioning) {

      this.domain = this.domain || 'functioning';

      this.menu['functioning'] = [];
      const functioning = this.menu['functioning'];

      this.goalsSub = this.dataService.getGoals(this.domain).subscribe(
        data => {
          for (const param in schema.functioning.params) {
            const paramMenu = this.menuItem(param, param, 'functioning', param, null, null, schema.functioning.params[param].color);
            functioning[param] = paramMenu;
            if ( this.maxSubParamLength < Object.keys(schema.functioning.params[param].subparams).length ) {
              this.maxSubParamLength = Object.keys(schema.functioning.params[param].subparams).length;
            }
    
            Object.keys(schema.functioning.params[param].subparams).map(subparam => {
              const count = data.filter(e => e.param_type === param && e.subparam_type === subparam).length;
              paramMenu.children.push(this.menuItem(subparam, schema.functioning.params[param].subparams[subparam].name, 'functioning', param, schema.functioning.params[param].subparams[subparam].category, schema.functioning.params[param].subparams[subparam].icon, '#f5a623', count));
            });
          }
        }
      );

      
    }

    console.log(this.menu);

  }

  private menuItem(label, name, domain, param = null, subparam = null, icon = null, color = '#f5a623', count = 0) {
    let item = {label, name, children: [], query: {domain} as any, icon, color, count};

    if (param !== null) {
      item.query.param = param;

      if (subparam !== null) {
        item.query.subparam = subparam;
      }
    }

    return item;
  }

  get params() {
    return this.menu[this.domain]
  }

  get domains() {
    return Object.keys(this.menu);
  }

  get paramHeader() {
    return Object.keys(this.menu[this.domain]);
  }

  get maxSubParamCount() {
    return Array(this.maxSubParamLength).fill(1).map((x,i)=>i);
  }

  setDomain(d: string) {
    this.domain = d;
  }
}
