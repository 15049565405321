<div class="modal-header">
  <h4 class="modal-title">LEVELS</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pb-5">

  <ng-container *ngIf="(!sub || sub.closed); else loader">
    <table *ngIf="categories.length" class="table-responsive">
      <thead>
      <tr>
        <th>LEVEL</th>
        <th>1</th>
        <th>2</th>
        <th>3</th>
        <th>4</th>
        <th>5</th>
        <th>6</th>
        <th>7</th>
        <th>8</th>
        <th>9</th>
        <th>10</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let category of categories">
        <th>{{category.name|t}}</th>
        <td *ngFor="let level of category.levels"><app-doughnut [value]="level"></app-doughnut></td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="!categories.length" class="text-center">No Levels to display</div>
  </ng-container>

  <ng-template #loader>
    <div *ngIf="sub && !sub.closed" class="text-center blink">Loading...</div>
  </ng-template>

</div>
