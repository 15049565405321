<div class="wrapper">
  <div class="toolbar">
    <h3 class="ml-0 pointer breadcrumb-back" [routerLink]="['/gamification/goals']" [queryParams]="{domain: domain}"><i class="fas fa-chevron-left"></i></h3>
    <h3 class="text-left">{{started?'Launched':'Created'}} Goals for {{subparam_label | t}}</h3>
    <button class="btn btn-sm btn-warning btn-add text-white mr-0 px-2" (click)="editGoal()" *ngIf="!started">
      <i class="fa fa-plus" [ngClass]="{'fa-plus': goals.length == 0, 'fa-pen': goals.length > 0}"></i>
    </button>

    <button class="btn btn-sm btn-warning text-white mr-0 px-2" (click)="startGoals()" *ngIf="batchId" [disabled]="(startGoalsSub && !startGoalsSub.closed) || started">
      {{started ? "Goals Started" : "Start Goals"}}
    </button>

  </div>

  <div class="goalsdiv">
    <div style="justify-content: flex-start; flex-direction: row; border-bottom: 2px solid #828282;">
      <div style="width: 60px">No</div>
      <div>Goals</div>
    </div>
    <div *ngIf="!(goalsSub && !goalsSub.closed) && goals[0] && goals[0].goals.length" class="goalsrows-container">
      <div class="goalsrow" *ngFor="let goal of goals[0].goals">

        <div class="d-flex " style=" border-radius: 4px; flex:1; ">
          <div class="d-flex align-items-center justify-content-center " style="flex:0;min-width: 50px;">
            {{goal.goal_batch_rank}}.{{goal.goal_batch_order}}</div>
          <div style="flex:1; padding: 16px 100px 16px 16px;">
            For the next {{goal.target}} {{goal.unit_label}} I obtain a score of {{goal.subparam_value}} for {{goal.subparam_type | t}}
          </div>
        </div>
        <img (click)="editGoal()" style="width: 25px;height: 25px;margin-left: 15px;cursor: pointer;" src="assets/images/ic_edit.svg">
      </div>
    </div>
    <div class="goalsrows-container text-center font-weight-normal" *ngIf="!(goalsSub && !goalsSub.closed) && (!goals[0] || goals[0].goals.length == 0)">
      No added goals to display
    </div>
    <div class="goalsrows-container text-center" *ngIf="goalsSub && !goalsSub.closed">
      <span class="blink font-weight-normal">Loading goals</span>
    </div>
  </div>
</div>