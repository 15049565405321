import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data.service';
import {of, Subscription} from 'rxjs';
import {StorageService} from '../../../services/storage.service';
import {TreeviewComponent, TreeviewItem} from 'ngx-treeview';
import { SplitPipe } from "../../../pipes/split.pipe";
import { map, mergeAll } from 'rxjs/operators';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  item: any;
  @ViewChild('checkboxtree') checkboxtree: TreeviewComponent;
  sub: Subscription;
  public config = {
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 5000
  };
  items: TreeviewItem[] = [];
  public addedUsers = [];
  public users = [];
  public searchUserTerm = '';

  private currentschema: {};
  title: any;
  description: any;
  invite_only: any;

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private storage: StorageService,
    private splitPipe: SplitPipe
  ) {

  }

  ngOnInit(): void {

    const user = this.dataService.getProjectUsers().pipe(
      map((data) => {
        this.users = data.filter(u => !u.communities.length && (u.system && u.system.system_type === this.storage.get('project').systems[0].system_type));
        if (this.item) {
          return this.dataService.getGroupUsers(this.item.id);
        }
        else {
          return of([]);
        }
      }),
      mergeAll()
    );
    this.sub = user.subscribe(data => {
      this.addedUsers = data;
      this.users = this.users.concat(this.addedUsers);
    });

    let config = this.storage.get('project');
    if (this.item) {
      this.title = this.item.name;
      this.description = this.item.description;

      this.dataService.getProjectGroupsSchema(this.item.id).subscribe(data => {

        Object.keys(data['schema']).map(project => {
          if(typeof this.storage.get('project').configuration.schema[project] == 'undefined') {
            delete data['schema'][project];
          }
        });

        this.items = this.convertSchema(data['schema']);
        this.currentschema = data['schema'];
        console.log(this.items);
      });
    } else {
      this.items = this.convertProjectSchema(config.configuration.schema);
    }
  }

  convertSchema(schema) {
    console.log(schema);
    let newschema: TreeviewItem[] = [];
    Object.keys(schema).map(schemakey => {
      let children2: TreeviewItem[] = [];
      Object.keys(schema[schemakey].params).map(paramkey => {
        let children1: TreeviewItem[] = [];
        Object.keys(schema[schemakey].params[paramkey].subparams).map(subparamkey => {
          children1.push(new TreeviewItem({
            text:  this.splitPipe.transform(subparamkey),
            checked: schema[schemakey].params[paramkey].subparams[subparamkey].status == 'enabled',
            value: subparamkey
          }));
        });
        children2.push(new TreeviewItem({
          text: this.splitPipe.transform(paramkey),
          checked: schema[schemakey].params[paramkey].status == 'enabled',
          children: children1,
          value: paramkey
        }));
      });
      newschema.push(new TreeviewItem({
        text: this.splitPipe.transform(schemakey),
        checked: schema[schemakey].status == 'enabled',
        children: children2,
        value: schemakey
      }));
    });

    return newschema;
  }

  convertProjectSchema(schema) {
    console.log(schema);
    let newschema: TreeviewItem[] = [];
    Object.keys(schema).map(schemakey => {
      let children2: TreeviewItem[] = [];
      let notdyn = !!schema[schemakey].params;
      console.log(schema[schemakey]);
      let params = notdyn ? schema[schemakey].params : schema[schemakey].dynafleet.params;
      Object.keys(params).map(paramkey => {
        let children1: TreeviewItem[] = [];
        let ar = (!Array.isArray(params[paramkey].subparams)) ? Object.keys(params[paramkey].subparams) : params[paramkey].subparams;
        ar.map((subparamkey, index) => {
          children1.push(new TreeviewItem({
            text: this.splitPipe.transform(subparamkey),
            checked: false,
            value: subparamkey
          }));
        });
        children2.push(new TreeviewItem({
          text: this.splitPipe.transform(paramkey),
          checked: false,
          children: children1,
          value: paramkey
        }));
      });
      newschema.push(new TreeviewItem({
        text: this.splitPipe.transform(schemakey),
        checked: false,
        children: children2,
        value: schemakey
      }));
    });

    return newschema;
  }

  onSelectedChange($event) {
    let schema = {};
    if (this.checkboxtree) {
      this.checkboxtree.items.map(item1 => {
        let params = {};
        let paramChecked = false;
        item1.children.map(item2 => {
          let subparams = {};
          let subparamChecked = false;
          if (typeof item2.children !== 'undefined') {
            item2.children.map(item3 => {
              subparams[item3.value] = {status: item3.checked ? 'enabled' : 'disabled'};
              if ( item3.checked ) {
                paramChecked = true;
                subparamChecked = true;
              }
            });
          }
          
          params[item2.value] = {subparams: subparams};
          params[item2.value]['status'] = subparamChecked || item2.checked ? 'enabled' : 'disabled';
        });
        
        schema[item1.value] = {params: params};
        schema[item1.value]['status'] = paramChecked || item1.checked ? 'enabled' : 'disabled';

      });
    }
    this.currentschema = schema;

  }

  onFilterChange($event) {
    console.log($event);
  }

  filterUsers() {
      if (this.searchUserTerm === '') return this.users;
      return this.users.filter(item => (item.first_name || '').includes(this.searchUserTerm) || (item.last_name || '').includes(this.searchUserTerm));
  }

  isUserInGroup(u: any) {
    return this.addedUsers.some(uu => {
      return uu.user_id == u.user_id;
    });
  }

  getUserImage(user_id: any) {
    this.dataService.getUserPicture(user_id, 500, 500).subscribe(data => {
      console.log(data);
    });
  }

  uids = [];

  userToggle(event: any, uid) {
    let f = event.target.checked;
    if (!this.item) {
      if (f) {
        this.uids.push(uid);
      } else {
        let index = this.uids.indexOf(uid);
        this.uids.splice(index, 1);
      }
      return;
    }


    let o = f ? this.dataService.addGroupMembers(this.item.id, uid) : this.dataService.removeGroupMembers(this.item.id, uid);
    o.subscribe(data => {

      },
      err => {
        console.log(err);
      });
  }

  onItemCheckedChange(item, checked) {
    console.log(item);
    console.log(checked);

  }

  submitSchema() {
    this.invite_only = false;

    if (this.item) {
      this.dataService.editProjectGroups(this.item.id, this.title, this.description, this.invite_only).subscribe(data => {

      });
      this.sub = this.dataService.submitGroupSchema(this.item.id, this.currentschema).subscribe(data => {
        this.activeModal.close();
      });
    } else {
      this.sub = this.dataService.addProjectGroups(this.title, this.description, this.invite_only, this.storage.get('project').systems[0].system_type).subscribe(data => {
        let id = data['id'];
        this.uids.map(uid => {
          this.sub = this.dataService.addGroupMembers(id, uid).subscribe(data => {
          });
          
        });
        this.sub = this.dataService.submitGroupSchema(id, this.currentschema).subscribe(data => {
        });
        
        this.activeModal.close('save');
        // window.location.reload();

      });
    }
  }
}
