import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, of, Subscription } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../../../services/data.service";
import { catchError, defaultIfEmpty, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AlertPopupComponent } from "../../../_shared/alert-popup/alert-popup.component";

@Component({
  selector: 'app-add-fact',
  templateUrl: './add-fact.component.html',
  styleUrls: ['./add-fact.component.scss']
})
export class AddFactComponent implements OnInit, OnDestroy {

  item: any;

  id: number;

  title = '';
  description = '';
  domain = '';
  param = '';
  subparam = '';
  subparam_label = '';
  private system: string;

  filesToUpload = [];
  videoToUpload = [];
  sub: Subscription;

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;

    if (this.item) {
      this.domain = this.item.domain_type;
      this.param = this.item.param_type;
      this.subparam = this.item.subparam_type;
      this.subparam_label = this.dataService.project['configuration']['schema'][this.domain]?.params[this.param].subparams[this.subparam]['name'];

      this.id = this.item.known_id;
      this.title = this.item.title;

      const info = (this.item.pages || []).length > 0 ? this.item.pages[0] : {};
      this.description = info.description;

      const loadImgs = (info.images || []).map(i => this.loadImage(i.image_id));
      combineLatest(loadImgs).subscribe(imgs => {
        imgs.forEach(i => {
          this.filesToUpload.push(i);
        });
      });

      const loadVideos = (info.videos || []).map(i => this.loadVideo(i.video_id));
      combineLatest(loadVideos).subscribe(videos => {
        videos.forEach(i => {
          this.videoToUpload.push(i);
        });
      });
    }

  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  loadImage(image_id) {
    // return this.dataService.getFactImage(this.item.known_id, image_id).pipe(
    //   map(img => ({
    //     image_id: image_id,
    //     item: img,
    //     url: URL.createObjectURL(img), mode: 'keep'
    //   }))
    // );
  }

  loadVideo(video_id) {
    // return this.dataService.getFactsVideo(this.item.known_id, video_id)
    //   .pipe(
    //     map(video => ({
    //       video_id: video_id,
    //       item: video,
    //       url: video.url, mode: 'keep',
    //       thumbnail: '/assets/images/thumbnail.jpg'
    //     }))
    //   );
  }

  closeModal() {
    this.modal.close();
  }

  addFilesToUpload(files: any) {
    Object.values(files).map(f => {
      //todo: validate file for upload
      this.filesToUpload.push({ item: f, url: URL.createObjectURL(f), mode: 'add' });
    });
    console.log(this.filesToUpload);
  }

  remove(index: number) {
    if (this.filesToUpload[index].mode == 'keep') {
      this.filesToUpload[index].mode = 'del';
    } else {
      this.filesToUpload.splice(index, 1);
    }
    console.log(this.filesToUpload);
  }

  addVideoToUpload(files: any) {
    Object.values(files).map((f: Blob) => {
      //todo: validate file for upload

      this.dataService.generateThumbnail(f).then(thumbnail => {
        this.videoToUpload.push({ item: f, url: URL.createObjectURL(f), mode: 'add', thumbnail: thumbnail });
      });
    });

    console.log(this.videoToUpload);
  }

  removeVideo(index: number) {
    if (this.videoToUpload[index].mode == 'keep') {
      this.videoToUpload[index].mode = 'del';
    } else {
      this.videoToUpload.splice(index, 1);
    }
    console.log(this.videoToUpload);
  }


  submit() {
    let body = {
      title: this.title,
      message: this.description,
      project_id: this.dataService.project.project_id,
      domain_type: this.domain,
      param_type: this.param,
      subparam_type: this.subparam,
      system_type: this.system
    };

    if (this.id) {
      body['known_id'] = this.id;
    }

    // this.sub = this.dataService.editFact(body).pipe(
    //   tap(result => {
    //     console.log(result);
    //     this.id = result.id;
    //   }),
    //   switchMap(result => combineLatest(
    //     this.filesToUpload
    //       .filter(img => img.mode == 'add' || img.mode == 'del')
    //       .map(img => this.postOrDeleteImage(result.id, img))
    //   )),
    //   defaultIfEmpty([]),
    //   switchMap(() => combineLatest(
    //     this.videoToUpload
    //       .filter(video => video.mode == 'add' || video.mode == 'del')
    //       .map(video => this.postOrDeleteVideo(this.id, video))
    //   )),
    //   defaultIfEmpty([]),
    //   // mergeMap(result => combineLatest(
    //   //   this.filesToUpload
    //   //     .filter(img => img.mode == 'add' || img.mode == 'del')
    //   //     .map(img => this.postOrDeleteImage(result.id, img))
    //   // )),
    //   // defaultIfEmpty([])
    // ).subscribe(res => {
    //   this.modal.close('saved');
    // });
  }

  private postOrDeleteImage(id, img: any) {

    if (img.mode == 'add') {
      // return this.dataService.postFactImage(id, img.item).pipe(
      //   catchError((e) => {
      //     const ref = this.modalService.open(AlertPopupComponent, { centered: true });
      //     ref.componentInstance.title = 'Error';
      //     ref.componentInstance.msg = 'Error while uploading file "' + img.item.name + '". ' + e.statusText;
      //     return from(ref.result);
      //   }),
      //   tap(imgResult => {
      //     if (imgResult != null) {
      //       img.mode = 'keep';
      //       img.image_id = imgResult.image_id;
      //     }
      //   })
      // );
    }

    // return this.dataService.deleteFactImage(id, img.image_id).pipe(
    //   catchError((e) => {
    //     return of(null);
    //   })
    // );
  }

  private postOrDeleteVideo(id, video: any) {

    if (video.mode == 'add') {
      // return this.dataService.factsVideoPresignedUrl(id, video.item.name).pipe(
      //   catchError((e) => {
      //     const ref = this.modalService.open(AlertPopupComponent, { centered: true });
      //     ref.componentInstance.title = 'Error';
      //     ref.componentInstance.msg = 'Error while uploading file "' + video.item.name + '". ' + e.statusText;
      //     return from(ref.result);
      //   }),
      //   tap(videoResult => {
      //     if (videoResult != null) {
      //       video.mode = 'keep';
      //       video.video_id = videoResult.video_id;
      //     }
      //   }),
      //   mergeMap(result => this.dataService.uploadFileToS3(result, video.item)),
      // );
    }

    // return this.dataService.deleteFactsVideo(id, video.video_id).pipe(
    //   catchError((e) => {
    //     return of(null);
    //   })
    // );
  }

  get valid() {
    return this.description;
  }

}
