import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../../../services/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddProsnconsComponent } from "../add-prosncons/add-prosncons.component";
import { Subscription } from "rxjs";
import { ConfirmationComponent } from "../../../_shared/confirmation/confirmation.component";
import { VideoPopupComponent } from 'src/app/components/_shared/video-popup/video-popup.component';
import { VjsPlayerComponent } from 'src/app/components/_shared/vjs-player/vjs-player.component';
import { map } from 'rxjs/operators';
import { AlertPopupComponent } from 'src/app/components/_shared/alert-popup/alert-popup.component';

@Component({
  selector: 'app-prosnconsedit',
  templateUrl: './prosnconsedit.component.html',
  styleUrls: ['./prosnconsedit.component.scss']
})
export class ProsnconseditComponent implements OnInit, OnDestroy {

  pros = [];
  cons = [];

  domain = '';
  param = '';
  subparam = '';
  subparam_label = '';
  private system: string;
  dataSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {

    this.system = this.dataService.project.systems[0].system_type;

    this.route.queryParams.subscribe(query => {
      this.domain = query.domain;
      this.param = query.param;
      this.subparam = query.subparam;
      this.subparam_label = this.dataService.project['configuration']['schema'][this.domain]?.params[this.param].subparams[this.subparam]['name'];
      this.updateData();
    });
  }

  ngOnDestroy() {
    this.dataSub && this.dataSub.unsubscribe();
  }

  updateData() {
    this.pros = [];
    this.cons = [];
    this.dataSub = this.dataService.getProsncons(this.system, this.domain, this.param, this.subparam, this.dataService.project.language).subscribe(items => {
      const { pros, cons } = items;
      console.log(pros);
      this.pros = pros;
      this.cons = cons;
    });
  }

  add(type: string) {
    const ref = this.modal.open(AddProsnconsComponent, { size: 'xl' });
    const c = ref.componentInstance as AddProsnconsComponent;
    c.domain = this.domain;
    c.param = this.param;
    c.subparam = this.subparam;
    c.subparam_label = this.subparam_label;
    c.type = type;

    ref.result.then(result => {
      this.updateData();
    }).catch(result => {
      this.updateData();
    })
  }
}
