import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddQuestionComponent} from './add-question/add-question.component';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  surveyQuestions = [];
  type: any;
  systype = 'psychological_profile';
  types = [];
  stypes = ['psychological_profile', 'general'];
  surveys = {};
  loading = false;
  private activesurvey: any;

  constructor(
    private dataService: DataService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.dataService.getSurveys(this.systype).subscribe(data => {
      this.loading = false;

      this.types = [];
      this.surveys = {};
      data.map(sur => {
        this.surveys[sur.domain_type] = sur;
        this.types.push(sur.domain_type);
      });
      this.type = data.length > 0 ? this.types[0] : null;
      this.getSurvey(this.type);
    });
  }

  getSurvey(t: any) {
    this.surveyQuestions = [];
    this.activesurvey = this.surveys[t];
    if (t) {
      this.loading = true;
      this.dataService.getSurvey(this.activesurvey.survey_id).subscribe(data => {
        this.surveyQuestions = Object.values(data['questions']);
        let idx = 0;
        this.surveyQuestions.forEach( q => {
          if (q.questionType.type != 'DB') {
            q['number'] = ++idx;

            q['choicesArray'] = Object.values(q.choices);

          }
        });
        // console.log(this.surveyQuestions);
        this.loading = false;
        // this.addQuestion(survey);
      });
    }
  }

}
