import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DriverBadgesComponent} from './driver-badges/driver-badges.component';
import {DriverLevelsComponent} from './driver-levels/driver-levels.component';
import {from, Subscription} from 'rxjs';
import {DataService} from '../../services/data.service';
import {map, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {ContextMenuComponent, ContextMenuService} from 'ngx-contextmenu';
import {ViewGroupComponent} from './view-group/view-group.component';
import {EditGroupComponent} from './edit-group/edit-group.component';
import {AddGroupComponent} from './add-group/add-group.component';
import {AddDriverComponent} from './add-driver/add-driver.component';
import {StorageService} from '../../services/storage.service';
import { AlertPopupComponent } from '../_shared/alert-popup/alert-popup.component';
import { ConfirmationComponent } from '../_shared/confirmation/confirmation.component';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit, OnDestroy {
  public system: string;
  items = [];
  indgroup: number = 1;
  type: string = 'Safety';

  pageSize = 8;
  p = 1;

  domains: string[] = [];
  domain: string;
  driverSub: Subscription;
  groupSub: Subscription;
  _subs: Subscription[] = [];
  groups = [];
  menuitems = ['1', '2', '3'];
  @ViewChild(ContextMenuComponent) public contextMenu: ContextMenuComponent;
  searchfield = '';
  sort = {
    drivers: {
      column: '',
      asc: 1,
      subfield: null,
    },
    group: {
      column: '',
      asc: 1,
      subfield: null,
    },
  };
  psychological_profile_type_dict={};
  inviteonly=true;

  set subs(value: Subscription) {
    this._subs.push(value);
  }

  constructor(
    private modal: NgbModal,
    private dataService: DataService,
    private contextMenuService: ContextMenuService,
    private storage: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.system = this.dataService.project.systems && this.dataService.project.systems.length ? this.dataService.project.systems[0].system_type : '';
    let proj = this.storage.get('project');
    this.psychological_profile_type_dict=proj.configuration.psychological_profile_type_dict;
    this.domains = Object.keys(proj.configuration.schema);
    this.domain = this.domains[0];
    console.log(this.psychological_profile_type_dict);
    this.driverSub = this.dataService.getProjectUsers(true).pipe(
      map(result => result.map(d => {
        const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
        d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
        return d;
      }))
    ).subscribe(result => {
      this.items = result;
      console.log(this.items);
    });
    this.groupSub = this.dataService.getProjectGroups().subscribe(data => {
      this.groups = data;
    });
  }

  ngOnDestroy() {
    this.driverSub && this.driverSub.unsubscribe();
    this.groupSub && this.groupSub.unsubscribe();
    this._subs.forEach(s => s.unsubscribe());
  }

  pageChanged($event: number) {
    this.p = $event;

  }

  badgesModal(user) {
    const ref = this.modal.open(DriverBadgesComponent, {size: 'xl', windowClass: 'badgeModal'});
    ref.componentInstance.userId = user.user_id;
  }

  levelsModal(user) {
    const ref = this.modal.open(DriverLevelsComponent, {size: 'lg'});
    ref.componentInstance.userId = user.user_id;
  }

  onContextMenu($event: MouseEvent, item: any) {
    this.contextMenuService.show.next({
      contextMenu: this.contextMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();


  }

  viewProfile(item: any) {
    const ref = this.modal.open(ViewGroupComponent, {size: 'lg'});
    ref.componentInstance.item = item;
  }

  editProfile(item: any) {
    const ref = this.modal.open(this.indgroup == 1 ? AddDriverComponent : EditGroupComponent, {size: 'xl'});
    ref.componentInstance.item = item;
    ref.componentInstance.domain = this.domain;
    ref.result.then((data)=>{
      if (this.indgroup == 1) {
        this.driverSub = this.dataService.getProjectUsers(true).pipe(
          map(result => result.map(d => {
            const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
            d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
            return d;
          }))
        ).subscribe(result => {
          this.items = result;
          console.log(this.items);
        });
      }
      else {
        this.groupSub = this.dataService.getProjectGroups().subscribe(data => {
          this.groups = data;
        });
        this.driverSub = this.dataService.getProjectUsers(true).pipe(
          map(result => result.map(d => {
            const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
            d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
            return d;
          }))
        ).subscribe(result => {
          this.items = result;
          console.log(this.items);
        });
      }
    })
  }

  deleteProfile(item: any) {
    let ref = this.modal.open(ConfirmationComponent);
    let component = ref.componentInstance as ConfirmationComponent;
    component.title = `Remove Gruop`;
    component.message = `Are you sure you want to remove this group?`;
    ref.result.then(result => {
      if (result == 'yes') {
        this.groupSub = this.dataService.deleteGroup(item.id).pipe(
          switchMap(m => this.dataService.getProjectGroups())
      ).subscribe(data => {
        this.groups = data;
      }, err => {
        const ref = this.modal.open(AlertPopupComponent, {centered: true});
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = err.error.message;
        return from(ref.result);
      });
      }
    }).catch(() => { });
  }

  addGroupPopup() {
    const ref = this.modal.open(EditGroupComponent, {size: 'xl'});
    console.log(ref);
    ref.result.then((data)=>{
      if(data==='save'){
        this.ngOnInit();
      }
    })
  }

  filterItems() {
    return this.sortItems(this.sort.drivers.column, this.sort.drivers.asc, this.sort.drivers.subfield, this.items.filter(item => {
      if(this.inviteonly && !item.in_service)return false;
      item.trucker_type = item.profile.trucker_type;
      item.psychological_profile = item.profile.psychological_profile;
      return (item['first_name'] + ' ' + item['last_name']).toLowerCase().indexOf(this.searchfield.toLowerCase()) > -1;

    }));
  }

  filterGroups() {

    return this.sortItems(this.sort.group.column, this.sort.group.asc, this.sort.group.subfield, this.groups.filter(item => {
      return item['name'].toLowerCase().indexOf(this.searchfield.toLowerCase()) > -1;
    }));
  }

  addDriverPopup() {
    const ref = this.modal.open(AddDriverComponent, {size: 'lg'});
    ref.componentInstance.domain = this.domain;

  }

  setDomain(d) {
    this.domain = d;
  }

  getPages() {
    return Math.ceil((this.indgroup == 1 ? this.filterItems() : this.filterGroups()).length / this.pageSize);
  }

  sortItems(column, asc, subfield, array) {
    return array.sort((a, b) => {
      if (!a[column]) {
        a[column] = '';
      }
      if (!b[column]) {
        b[column] = '';
      }
      if (subfield) {
        if (!a[column][subfield]) {
          a[column][subfield] = '';
        }
        if (!b[column][subfield]) {
          b[column][subfield] = '';
        }
      }
      if (subfield) {
        return a[column][subfield].localeCompare(b[column][subfield]) * asc;
      }
      return a[column].localeCompare(b[column]) * asc;
    });
  }

  toggleSort(arrayname, field: string, subfield: string) {
    if (this.sort[arrayname].column != field) {
      this.sort[arrayname].column = field;
      this.sort[arrayname].asc = 1;
    } else {
      this.sort[arrayname].asc *= -1;
    }
    this.sort[arrayname].subfield = subfield;
  }
}
