import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbTypeaheadSelectItemEvent
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import 'moment-duration-format';
import { unitOfTime } from "moment";
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import { CustomAdapter, CustomDateParserFormatter } from "../../_shared/ngbdatepickeradapter/datepicker-adapter";
import hexToRgba from 'hex-to-rgba';

type ChartCfg = {
  id: string,
  api: string,
  label: string,
  params: any
};

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html',
  styleUrls: ['./scores.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ScoresComponent implements OnInit {
  //todo: dynamic chart shrink on desktop

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.chart.chart.resize();
  // }
  //
  // @ViewChild('chart')
  // chart: any;

  indgroup: number = 1;

  targetAudience: string = 'Target Audience';
  timeInterval: string = 'Time Interval';

  generalMenu = [];

  chartsCfg: ChartCfg[] = [];
  chartCfg: ChartCfg;
  chartData: any;

  from: NgbDate;
  until: NgbDate;

  system: string;

  chartId: string;
  defaultChartId: string;

  map: L.DrawMap;
  menu = [];

  leafletOptions: any;
  tiles: any[] = [];

  isMapOpen = false;

  trips: any[] = [];
  selected: any = null;

  flattenLegend = {};
  layergroup: L.LayerGroup;
  routing: L.Routing.Control;

  filterLegends = [];

  periods = {
    'day': {
      'title': 'Day',
      'prefix': null,
      'type': 'day',
      'amount': 1,
    },
    'week': {
      'title': 'Week',
      'prefix': 'w',
      'type': 'week',
      'amount': 1
    },
    'month': {
      'title': 'Month',
      'prefix': 'm',
      'type': 'month',
      'amount': 1,
    },
    '3month': {
      'title': '3 Months',
      'prefix': 'm',
      'type': 'month',
      'amount': 1,

    },
    'year': {
      'title': 'Year',
      'prefix': 'y',
      'type': 'year',
      'amount': 1,
    }
  }

  period = 'day'

  colors = ['rgba(248, 190, 31,', 'rgba(35, 178, 49,', 'rgba(220, 53, 69,', 'rgba(0, 123, 255,'];

  options = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      yAxes: [],
      xAxes: [{
        scaleLabel: {
          padding: 20
        },
        ticks: {
          autoSkip: true,
          maxRotation: 20,
          minRotation: 0
        }
      }]
    }
  };

  users: any[] = [];
  groups: any[] = [];
  search: (text: Observable<string>) => Observable<readonly any[]>

  userConfiguration = {};

  target: any;
  targetChartParams: any;
  formatter: (item: any) => string;

  private _domain: string;
  private _type: string;

  domains: string[] = [];
  loader: boolean = true;

  error: string = "";
  errorFound: boolean = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;

    this.formatter = (item) => item && item.label ? `${item.label}` : '';

    this.search = (text$: Observable<string>) => text$.pipe(
      // debounceTime(200),
      distinctUntilChanged(),
      // filter(term => term.length >= 2),
      map(term => {

        if (term.length < 2) {
          return [{ prefix: 'Group', label: `All`, value: { community_id: null, user_id: null } }];
        }

        const users = this.users.filter(x => x).map(user => ({ prefix: 'User', label: `${user.first_name} ${user.last_name}`, value: { user_id: user.user_id, community_id: null } }));
        const groups = this.groups.filter(x => x).map(group => ({ prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null } }));
        return [...groups, ...users].filter(item => item.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      })
    )


    this.dataService.getProjectConfigurationGeneral().pipe(
      tap(config => this.init(config)),
      switchMap(() => this.loadUsers()),
      switchMap(() => this.route.queryParams)
    ).subscribe(params => {
      this.loader = true
      this._type = params.type || 'general';
      this._domain = params.domain || this.domains[0];


      this.chartId = params.id || this.getDefaultChartId();

      this.menu.forEach(m => {
        m.children.forEach(mm => {
          if (mm.id == this.chartId) mm.collapsed = false;
          mm.children.forEach(mmm => {
            if (mmm.id == this.chartId) mm.collapsed = false;
          })
        })
      })

      this.chartCfg = this.chartsCfg.find(cfg => cfg.id == this.chartId);

      this.period = params.period || 'day';

      this.until = this.str2NgbDate(params.until, this.calendar.getToday());
      this.from = this.str2NgbDate(params.from, this.calendar.getPrev(this.calendar.getToday(), 'm', 1));

      if (!params.user_id && !params.community_id) {
        const label = params.audience ? params.audience : 'All Drivers';
        this.target = { prefix: 'Group', label: label, value: { community_id: null, user_id: null } };
        this.targetChartParams = {};
        if (this.users.length) {
          const user = this.sortUser(this.users)[0];
          this.dataService.getUserConfiguration(user.user_id).subscribe(result => {
            this.getUserSubParamConf(result);
          })
        }
      } else if (params.user_id) {
        const user = this.users.find(u => u.user_id == params.user_id);
        this.target = { prefix: 'User', label: `${user.system.cardio.short_name }`, value: { user_id: user.user_id, community_id: null } };
        this.targetChartParams = { user_id: params.user_id };

        this.dataService.getUserConfiguration(user.user_id).subscribe(result => {
          this.getUserSubParamConf(result);
        })
      } else if (params.community_id) {
        const group = this.groups.find(g => g.id == params.community_id);
        this.target = { prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null } };
        this.targetChartParams = { community_id: params.community_id };
      }

      this.errorFound = false;

      const chartParams = {
        ...this.chartCfg.params,
        ...this.targetChartParams,
        system_type: this.system,
        aggregate: this.period,
        from: this.ngbDate2Str(this.from),
        until: this.ngbDate2Str(this.until),
        project_id: this.dataService.project.project_id
      };

      this.dataService.getChart(this.chartCfg.api, chartParams).subscribe(result => {
        const data = result['data'];
        const { labels } = data;

        const datestrs = labels.map(element => {
          return moment(element).format('DD/MM/YYYY');
        });
        data.labels = datestrs;
        const updtes = {
          ...result,
          data
        };
        this.updateData(updtes);
        this.loader = false;
      },
        error => {
          this.error = error.error.message;
          this.loader = false;
          this.errorFound = true;
        });

      if (this.type === 'general') {
        this.options.scales.yAxes = [{
          ticks: {
            beginAtZero: true
          }
        }];
      }
      else if (this.type === 'domain') {
        this.options.scales.yAxes = [{
          ticks: {
            beginAtZero: true,
            max: 100
          }
        }];
      }

      console.log(this.chartCfg);
      console.log(chartParams);
      console.log(chartParams);
    });
  }

  parseId(data) {
    const DELIMITER = '/';

    const parsed = data.split(DELIMITER);
    const keys = ['domain', 'param', 'subparam'];
    let result = {};
    parsed.forEach((e, index) => {
      result[keys[index]] = e;
    });
    return result;
  }

  ngbDate2Moment(ngbObj: NgbDate) {
    const momentObj = moment();

    momentObj.month(ngbObj.month - 1);
    momentObj.date(ngbObj.day);
    momentObj.year(ngbObj.year);

    return momentObj;
  }

  getUserSubParamConf(conf) {
    Object.keys(conf).forEach(key => {
      this.userConfiguration[key] = {};
      if (typeof conf[key] === 'object' && 'params' in conf[key]) {
        Object.keys(conf[key]['params']).forEach(param => {
          this.userConfiguration[key][param] = {};
          if ('subparams' in conf[key]['params'][param]) {
            Object.keys(conf[key]['params'][param]['subparams']).forEach(subparam => {
              this.userConfiguration[key][param][subparam] = conf[key]['params'][param]['subparams'][subparam]['status'] === "enabled";
            });
          }
        });
      }
    });
  }

  isDisabled(menuItem) {
    const params = (menuItem.id || '').split('/');
    if (params.length === 3) {
      if (params[0] in this.userConfiguration &&
        params[1] in this.userConfiguration[params[0]] &&
        params[2] in this.userConfiguration[params[0]][params[1]])
        return !this.userConfiguration[params[0]][params[1]][params[2]];
    }
    return false;
  }

  updateData(data) {
    this.chartData = data['data'];

    if (!data['data']) return;
    let backgroundColor = '#f5a623';
    if (this.type === 'general') {
      var currentMenuItem = this.generalMenu.find(obj => {
        return obj.id === this.chartId
      });
      backgroundColor = currentMenuItem['color'];
    }
    else if (this.type === 'domain') {
      const category = this.parseId(this.chartId);

      if (category['domain']) {
        const currentDomain = this.menu.find(obj => {
          return obj.label === this.domain
        });
        backgroundColor = currentDomain['color'] || '#f5a623';

        if (category['param']) {
          const currentParam = currentDomain.children.find(obj => {
            return obj.label === category['param']
          });
          backgroundColor = currentParam['color'] || '#f5a623';
        }
      }
    }

    const momentFrom = this.ngbDate2Moment(this.from);
    const momentUntil = this.ngbDate2Moment(this.until);

    let periodObj = this.periods.day;
    if (this.period) periodObj = this.periods[this.period];
    let inCompleteStart = false, inCompleteEnd = false;
    for (let j = 0; j < this.chartData['labels'].length; j++) {
      if (this.period !== 'day') {
        let start = moment(this.chartData['labels'][j], 'DD/MM/YYYY').startOf(periodObj.type as unitOfTime.StartOf);
        let end = moment(this.chartData['labels'][j], 'DD/MM/YYYY').endOf(periodObj.type as unitOfTime.StartOf);

        if (j === 0) {
          start = momentFrom;
          let diffEnd = end.clone();
          if (diffEnd.add(1, 'day').diff(start, periodObj.type as unitOfTime.Diff) < 1) inCompleteStart = true;
        }
        if (j === this.chartData['labels'].length - 1) {
          end = momentUntil;
          let diffEnd = end.clone();
          if (diffEnd.add(1, 'day').diff(start, periodObj.type as unitOfTime.Diff) < 1) inCompleteEnd = true;
        }

        let diffEnd = end.clone();
        this.chartData['labels'][j] = [`${periodObj.prefix}${j + 1}`, `${start.format('DD/MM')} - ${end.format('DD/MM')} ${diffEnd.add(1, 'day').diff(start, periodObj.type as unitOfTime.Diff) < 1 ? '*' : ''}`];
      }
    }

    for (let j = 0; j < this.chartData['datasets'].length; j++) {
      this.chartData['datasets'][j]['backgroundColor'] = [];
      for (let i = 0; i < this.chartData['datasets'][j]['data'].length; i++) {
        let opacity = 1;
        if (this.period !== 'day') {
          if (i === 0) {
            opacity = inCompleteStart ? 0.5 : 1;
          }
          if (i === this.chartData['labels'].length - 1) {
            opacity = inCompleteEnd ? 0.5 : 1;
          }
        }
        let bgColor = hexToRgba(backgroundColor, opacity);
        this.chartData['datasets'][j]['backgroundColor'].push(bgColor)
      }
    }

    // for(let j=0;j<this.chartData['datasets'].length;j++){
    //   this.chartData['datasets'][j]['backgroundColor']=[];
    //   for(let i=0; i<this.chartData['datasets'][j]['data'].length;i++){
    //     this.chartData['datasets'][j]['backgroundColor'].push(backgroundColor);
    //   }
    // }
  }

  private str2NgbDate(str: string, def: NgbDate) {
    if (str) {
      const dd = str.split('/');
      return {
        year: parseInt(dd[2]),
        month: parseInt(dd[1]),
        day: parseInt(dd[0])
      } as NgbDate;
    }

    return def;
  }

  private ngbDate2Str(date: NgbDate) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  private init(config: any) {

    const schema = this.dataService.project.configuration.schema;

    for (const key in config[this.system]) {
      const item = config[this.system][key];
      const id = `general/${key}`;

      if (!this.defaultChartId) {
        this.defaultChartId = id;
      }

      this.chartsCfg.push(this.chartCfgItem(id, 'general', item.display_name, { type: key }));
      this.generalMenu.push(this.menuItem(id, item.display_name, item.display_name, false, item.icon, item.color));
    }

    if (schema.eco_efficiency) {
      this.domains.push('eco_efficiency');
      this.chartsCfg.push(this.chartCfgItem('eco', 'eco', 'eco_efficiency', {}));
      const eco = this.menuItem('eco', 'eco_efficiency', 'eco_efficiency');
      this.menu.push(eco);

      for (const param in schema.eco_efficiency[this.system].params) {
        const id = `eco/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'eco', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, param, true, null, schema.eco_efficiency[this.system].params[param].color);
        eco.children.push(paramMenu);

        Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
          const id = `eco/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'eco', subparam, { param_type: param, subparam_type: subparam }))
          paramMenu.children.push(this.menuItem(id, subparam, schema.eco_efficiency[this.system].params[param].subparams[subparam].name, false, schema.eco_efficiency[this.system].params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.safety) {
      this.domains.push('safety');
      this.chartsCfg.push(this.chartCfgItem('safety', 'safety', 'safety', {}));
      const safety = this.menuItem('safety', 'safety', 'safety');
      this.menu.push(safety);

      for (const param in schema.safety.params) {
        const id = `safety/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'safety', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, param, true, null, schema.safety.params[param].color);
        safety.children.push(paramMenu);
        Object.keys(schema.safety.params[param].subparams).map(subparam => {
          const id = `safety/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'safety', subparam, { param_type: param, subparam_type: subparam }))
          paramMenu.children.push(this.menuItem(id, subparam, schema.safety.params[param].subparams[subparam].name, false, schema.safety.params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.functioning) {
      this.domains.push('functioning');
      this.chartsCfg.push(this.chartCfgItem('functioning', 'functioning', 'functioning', {}));
      const functioning = this.menuItem('functioning', 'functioning', 'functioning');
      this.menu.push(functioning);

      for (const param in schema.functioning.params) {
        const id = `functioning/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'functioning', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, param, true, null, schema.functioning.params[param].color);
        functioning.children.push(paramMenu);

        Object.keys(schema.functioning.params[param].subparams).map(subparam => {
          const id = `functioning/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'functioning', subparam, { param_type: param, subparam_type: schema.functioning.params[param].subparams[subparam].category }))
          paramMenu.children.push(this.menuItem(id, subparam, schema.functioning.params[param].subparams[subparam].name, false, schema.functioning.params[param].subparams[subparam].icon));
        });
      }
    }

  }

  navigate(params: any) {
    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: params });
  }

  private chartCfgItem(id: string, api: string, label: string, params: any): ChartCfg {
    return { id, api, label, params };
  }

  private menuItem(id, label, name, collapsed = false, icon = null, color = '#f5a623') {
    return { id, label, name, collapsed, icon, color, children: [] };
  }

  setPeriod(period: string) {
    this.navigate({ period });
  }

  setFrom($event: NgbDate) {
    this.navigate({ from: `${$event.day}/${$event.month}/${$event.year}` });
  }

  setUntil($event: NgbDate) {
    this.navigate({ until: `${$event.day}/${$event.month}/${$event.year}` });
  }

  loadUsers() {
    return this.users.length ? of([]) :
      combineLatest([this.dataService.getProjectRolesUsers(), this.dataService.getProjectGroups()]).pipe(
        tap(([users, groups]) => {
          this.users = users;
          this.groups = groups;
        })
      );
  }


  setTarget($event: NgbTypeaheadSelectItemEvent) {
    console.log($event);
    if ($event) {
      this.navigate($event.item.value)
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  setTargetAudience(audience) {
    if (audience) {
      this.navigate({ user_id: null, community_id: null, audience: audience });
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  setTargetUser(user) {
    console.log(user);
    if (user) {
      this.target = { prefix: 'User', label: `${user.system.cardio.short_name }`, value: { community_id: null, user_id: user.user_id } };
      this.navigate({ user_id: user.user_id, community_id: null });
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  setTargetGroup(group) {
    console.log(group);
    if (group) {
      this.target = { prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null } };
      this.navigate({ user_id: null, community_id: group.id });
    } else {
      this.navigate({ user_id: null, community_id: null })
    }
  }

  set type(value: string) {
    if (this._type != value) {
      this.navigate({ type: value, id: null, domain: null });
    }
  }

  get type(): string {
    return this._type;
  }

  get typeLabel(): string {
    return {
      'general': 'General Indicators',
      'domain': 'Domain Scores',
      'trips': 'Trips'
    }[this._type];
  }

  set domain(value: string) {
    if (this._domain != value) {
      this.navigate({ type: 'domain', id: null, domain: value });
    }
  }

  get domain(): string {
    return this._domain;
  }

  private getDefaultChartId() {
    if (this._type == 'general') {
      return this.defaultChartId;
    }

    return {
      'eco_efficiency': 'eco',
      'safety': 'safety',
      'functioning': 'functioning'
    }[this._domain];

  }

  sortGroup(array) {
    return array.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
  }

  sortUser(array) {
    return array.sort((a, b) => {
      if(a.first_name + a.last_name < b.first_name + b.last_name) { return -1; }
      if(a.first_name + a.last_name > b.first_name + b.last_name) { return 1; }
      return 0;
    });
  }
}
