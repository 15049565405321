<div style="min-height: 100%; display: flex;flex-direction: column; width: 100%">

  <nav class="navbar topnav navbar-expand-lg menu-grey">
    <button class="navbar-toggler text-warning" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>

    <a class="navbar-brand" [routerLink]="'.'"><img [src]="getProjectlogo()"></a>


    <!-- Step 2: Add the ngbCollapse directive to the element below. -->
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse order-last order-lg-2 z-index-10">
      <div class="navbar-nav navbar-light text-white">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/']" [routerLinkActiveOptions]="{exact: true}"
          (click)="isMenuCollapsed = true">Drivers</a>
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/leaderboards']"
          (click)="isMenuCollapsed = true">Leaderboards</a>
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/results']"
          (click)="isMenuCollapsed = true">Results</a>
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/gamification']"
          (click)="isMenuCollapsed = true">Gamification</a>
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/forum']"
          (click)="isMenuCollapsed = true">Forum</a>
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/contact']"
          (click)="isMenuCollapsed = true">Contact us</a>
        <a class="nav-link d-inline-block d-lg-none" (click)="logout()">Logout</a>
      </div>
    </div>

    <div class="logincontainer order-lg-3">
      <!--      <div class="avatar-small" style="background-image: url('/assets/images/face-1.png')"></div>-->

      <div ngbDropdown display="dynamic" placement="bottom-right">
        <span class="d-lg-none d-inline-block lh60">{{ getUserName() }}</span>
        <button class="btn btn-link toggle d-none d-lg-inline-block" ngbDropdownToggle><span class="d-none d-lg-inline-block lh60">Welcome,
            {{ getUserName() }}</span></button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="logout()">Logout</button>
        </div>
      </div>

    </div>



  </nav>
  <router-outlet></router-outlet>

</div>