import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { SplitPipe } from "../../../../pipes/split.pipe";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-edit-goals-modal',
  templateUrl: './edit-goals-modal.component.html',
  styleUrls: ['./edit-goals-modal.component.scss']
})
export class EditGoalsModalComponent implements OnInit {

  domain: string;
  param: string;
  subparam: string;
  subparam_label: string;

  goalTab: number = 0;
  batchId: number = null;
  started: boolean = false;
  goals: any[];
  title: string;

  form: FormGroup;

  mode = 'edit';
  newGoals: any[] = [];
  saving: Subscription;

  constructor(
    public modal:NgbActiveModal,
    private fb: FormBuilder,
    private dataService: DataService,
    private t: SplitPipe
  ) { }

  ngOnInit(): void {

    this.started = false;
    let batch = [];
    this.batchId = null;
    this.title = this.t.transform(this.subparam_label);

    if (this.goals.length) {
      this.started = this.goals[0].started;
      batch = this.goals[0].goals;
      this.batchId = this.goals[0].id;
      this.title = this.goals[0].title;
    }

    console.log(batch);

    this.form = this.fb.group({
      'project_id': [this.goals.length ? this.goals[0].project_id : this.dataService.project.project_id],
      'title': [this.goals.length ? this.goals[0].title : this.title],
      'system_type': [this.goals.length ? this.goals[0].system_type : this.dataService.system],
      'strategy': ['fail'],
      'strategy_base': ['aggregate'],
      'subparam_type': this.subparam,
      'param_type': this.param,
      'domain_type': this.domain,
      'params': this.fb.array(this.initParams(batch))
    });

  }

  initParams(batch: any[]) {

    let params = [] as FormGroup[];

    if (batch.length) {

      batch.sort((a, b) => a.goal_batch_rank - b.goal_batch_rank || a.goal_batch_order - b.goal_batch_order);

      batch.forEach(goal => {
        let rank = goal.goal_batch_rank - 1;

        if (!params[rank]) params[rank] = this.fb.group({goals: this.fb.array([])});
        let goals = params[rank].get('goals') as FormArray;
        goals.push(this.initParam(goal.goal_batch_rank, goal.goal_batch_order, goal));
      });

    } else {
      return         [
        this.fb.group({goals: this.fb.array([this.initParam(1, 1)])}),
        this.fb.group({goals: this.fb.array([this.initParam(2, 1)])}),
        this.fb.group({goals: this.fb.array([this.initParam(3, 1)])}),
        this.fb.group({goals: this.fb.array([this.initParam(4, 1)])}),
      ];
    }

    console.log(params);

    return params;
  }

  initParam(rank: number, order: number, goal: any = null) {

    let target = order == 1
      ? [goal ? goal.target : '', [Validators.required, Validators.pattern(/^\d+$/)]]
      : [goal ? goal.target : ''];

    return this.fb.group({
      'target': target,
      'target_unit': [goal ? goal.unit_label : 'km'],
      'subparam_value': [goal ? goal.subparam_value : '', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(/^[+-]?\d+(\.\d+)?$/)]],
      'goal_batch_rank': [rank],
      'goal_batch_order': [order]
    });
  }

  closeModal() {
    this.modal.close();
  }

  get params() {
    return this.form.get('params') as FormArray;
  }

  goalParams(rank: number) {
    return this.form.get(`params.${rank}.goals`) as FormArray;
  }

  subparamValue(rank, order) {
    return this.form.get(`params.${rank}.goals.${order}.subparam_value`);
  }

  addGoal(rank) {
    let last = this.form.value.params[rank].goals.slice(-1,)[0];
    let a = this.form.get('params') as FormArray;
    let goals = a.at(rank).get('goals')  as FormArray;
    goals.push(this.initParam(rank+1, last.goal_batch_order+1));
    this.newGoals.push({rank: rank+1, order: last.goal_batch_order+1});
  }

  removeGoal(rank: number, order: number) {
    let goals = this.form.get(`params.${rank}.goals`) as FormArray;
    goals.removeAt(order);
  }

  mayRemove(rank: number) {
    let goals = this.form.get(`params.${rank}.goals`) as FormArray;
    let val = goals.at(goals.length - 1).value;
    return this.newGoals.findIndex(el => el.rank == val.goal_batch_rank && el.order == val.goal_batch_order) > -1;
  }

  onSubmit() {
    let value = JSON.parse(JSON.stringify(this.form.value));

    let params = [];

    value.params.forEach((param, i) => {
      param.goals.forEach((goal, j) => {
        params.push({
          target: parseInt(goal.target),
          target_unit: goal.target_unit,
          subparam_value: parseFloat(goal.subparam_value),
          goal_batch_rank: goal.goal_batch_rank,
          goal_batch_order: goal.goal_batch_order
        });
      });
    });

    value.params = params;

    if (this.batchId) {
      value['batch_id'] = this.batchId;
    }

    console.log(value);

    this.saving = this.dataService.postGoals(value).pipe(

    ).subscribe(result => {
      this.modal.close();
    });

  }

}
