import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router, CanActivateChild} from '@angular/router';
import { Observable, of } from 'rxjs';
import {DataService} from './data.service';
import {AuthService} from './auth.service';
import { map } from "rxjs/operators";

@Injectable()
export class UserGuard implements CanActivate, CanActivateChild, Resolve<any> {

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    if (!(this.dataService.project && this.dataService.project.configuration)) {
      this.router.navigate(['/choose-project'], {queryParams: {'backurl': state.url}});
      return false;
    }

    return true;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.dataService.user) {
      return this.dataService.getProjectList().pipe(
        map(config => {
          this.dataService.user = config.user;
          return true;
        })
      );
    }

    return of(true);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
