<div class="modal-header">
  <h4 class="modal-title">Goals for {{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

    <div class="text-center" [hidden]="domain == 'functioning'">
      <div class="btn-group btn-group-toggle mb-4" data-toggle="buttons" ngbRadioGroup
           name="radio1" [(ngModel)]="goalTab">
        <label *ngFor="let g of params.controls; let rank = index" ngbButtonLabel class="btn btn-light py-2 px-3">
          <input type="radio" [value]="rank" autocomplete="off" ngbButton> Challenge {{rank+1}}
        </label>
      </div>
    </div>

    <form (ngSubmit)="onSubmit()" [formGroup]="form" id="goals-form" style="padding: 0 20px;">
      <ng-container formArrayName="params">
        <div *ngFor="let dummy of params.controls; let rank = index" [hidden]="rank != goalTab" [formGroupName]="rank">

          <ng-container formArrayName="goals">

            <ng-container *ngFor="let goal of goalParams(rank).controls; let order = index" [formGroupName]="order">
              <div class="row align-items-center mb-4 px-3">
                <!-- <h5 class=" mb-0 blue flex-grow-1">{{'Challenge ' + (goalTab+1)}}</h5> -->
                <small class="mb-0">For the next </small>&nbsp;
                <div class="mx-1 w-25"><input type="text" class="form-control grayinput text-right" placeholder="Insert number" formControlName="target" required></div>&nbsp;
                <small class=" mb-0 text-right">km</small>
              </div>
              <div >
                <div class="row align-items-center mb-2 justify-content-end">
                  <small class="mb-0 col-3 text-right"> I obtain a score of</small>
                  <div class="col-3 text-center" *ngIf="mode == 'start'">{{ form.value.params[rank]['goals'][order]['subparam_value']  || '??'}}</div>
                  
                  <div class="col-3" *ngIf="mode == 'edit'">
                    <input
                      type="text"
                      class="form-control grayinput text-center"
                      placeholder="Insert score*"
                      [ngClass]="{'is-invalid': subparamValue(rank, order).invalid && subparamValue(rank, order).touched}"
                      formControlName="subparam_value" required>
                  </div>
                  <small class="mb-0 col-2">for {{ title }}</small>
                  <small class="mb-0 col-2 rank-label">{{'Goal ' + (rank+1) + '.'+ (order+1) +'.'}}</small>

                  <div class="col-2" *ngIf="mode=='edit' && !batchId">
                    <button *ngIf="mayRemove(rank)" type="button" class="btn btn-primary-outline mr-2" (click)="removeGoal(rank, order)">
                        <i class="fa fa-minus-circle btn-remove"></i>
                    </button>
                    <button type="button" class="btn btn-primary-outline text-white" (click)="addGoal(rank)">
                        <i class="fa fa-plus-circle btn-add"></i>
                    </button>
                  </div>
                  <small *ngIf="subparamValue(rank, order).invalid && subparamValue(rank, order).touched" class="col-12 text-form red text-center">
                    *Enter scores values >= 0 and <= 100 per goal.
                </small>
                </div>
<!--                <label class="mb-2" [hidden]="!form.value.params[rank]['goals'][0].target || !form.value.params[rank]['goals'][order]['subparam_value']">-->
<!--                  Voor de eerstvolgende {{ form.value.params[rank]['goals'][0].target || '??' }} km behaal ik minstens een-->
<!--                  score van {{ form.value.params[rank]['goals'][order]['subparam_value']  || '??'}} voor {{ title }}.-->
<!--                </label>-->
                <!--<hr *ngIf="order != 3">-->
              </div>
            </ng-container>
          </ng-container>

        </div>
      </ng-container>
    </form>
    <div class="text-left my-4" style="padding: 0 20px;">
        <small>
        *Enter scores values >= 0 and <= 100 per goal.
        </small>
    </div>
    <div class="text-center my-4">
      <button class="btn btn-warning text-white" form="goals-form" [hidden]="mode == 'start'" [disabled]="mode == 'start' || !form.valid || started">
        <span *ngIf="!saving || saving.closed">SAVE</span>
        <span *ngIf="saving && !saving.closed" class="blink">SAVING</span>
      </button>
    </div>

</div>
