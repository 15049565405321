<div class="resultspage w-100">
    <div class="top-nav-bar">
      <div class="top-nav-barmenu">
        <a *ngFor="let item of items" [routerLink]="item.routerLink"
              [routerLinkActive]="['active']">{{item.title}}</a>
      </div>
    </div>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
  
  </div>
  