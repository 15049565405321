import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from "./components/user/user.component";
import { LoginComponent } from "./components/login/login.component";
import { ChooseProjectComponent } from "./components/choose-project/choose-project.component";
import { DriversComponent } from "./components/drivers/drivers.component";
import { LeaderboardsComponent } from "./components/leaderboards/leaderboards.component";
import { UserGuard } from './services/user.guard';
import { GamificationComponent } from "./components/gamification/gamification.component";
import { GoalsComponent } from "./components/gamification/goals/goals.component";
import { ProsnconsComponent } from "./components/gamification/prosncons/prosncons.component";
import { TipsComponent } from "./components/gamification/tips/tips.component";
import { CreditsComponent } from "./components/gamification/credits/credits.component";
import { ShopComponent } from "./components/gamification/shop/shop.component";
import { SurveyComponent } from "./components/gamification/survey/survey.component";
import { PhasesComponent } from "./components/gamification/phases/phases.component";
import { ResultsComponent } from "./components/results/results.component";
import { ForumComponent } from "./components/forum/forum.component";
import { ForumMessageComponent } from "./components/forum-message/forum-message.component";
import { TipsEditComponent } from "./components/gamification/tips/tips-edit.component";
import { ProsnconseditComponent } from "./components/gamification/prosncons/prosnconsedit/prosnconsedit.component";
import { ContactComponent } from './components/contact/contact.component';
import { ParamGoalsComponent } from "./components/gamification/goals/param-goals/param-goals.component";
import { ReportsComponent } from './components/results/reports/reports.component';
import { TripsComponent } from './components/results/trips/trips.component';
import { ScoresComponent } from './components/results/scores/scores.component';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { FactsComponent } from './components/gamification/facts/facts.component';
import { FactsEditComponent } from './components/gamification/facts/facts-edit.component';

const routes: Routes = [
  {
    path: 'TermsandConditions/IDreamsTermsandConditions.pdf',
    pathMatch: 'full',
    component: TermsandconditionsComponent
  },
  {
    path: 'TermsandConditions/PrivacyPolicy',
    pathMatch: 'full',
    component: PrivacypolicyComponent
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'choose-project',
    pathMatch: 'full',
    component: ChooseProjectComponent
  },
  {
    path: '',
    component: UserComponent,
    canActivate: [UserGuard],
    resolve: [UserGuard],
    canActivateChild: [UserGuard],
    data: {
      roles: ['planner', 'projectleader']
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DriversComponent
      },
      {
        path: 'results',
        // pathMatch: 'full',
        component: ResultsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'trips',
            // component: ProsnconsComponent,
          },
          {
            path: 'reports',
            pathMatch: 'full',
            component: ReportsComponent,
          },
          {
            path: 'trips',
            pathMatch: 'full',
            component: TripsComponent,
          },
          {
            path: 'scores',
            pathMatch: 'full',
            component: ScoresComponent,
          },
        ]
      },
      {
        path: 'leaderboards',
        pathMatch: 'full',
        component: LeaderboardsComponent
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactComponent
      },
      {
        path: 'gamification',
        // pathMatch: 'full',
        component: GamificationComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'prosncons',
            // component: ProsnconsComponent,
          },
          {
            path: 'prosncons',
            pathMatch: 'full',
            component: ProsnconsComponent,
          },
          {
            path: 'prosncons-edit',
            pathMatch: 'full',
            component: ProsnconseditComponent,
          },
          {
            path: 'tips',
            pathMatch: 'full',
            component: TipsComponent,
          },
          {
            path: 'tips-edit',
            pathMatch: 'full',
            component: TipsEditComponent,
          },
          {
            path: 'facts',
            pathMatch: 'full',
            component: FactsComponent,
          },
          {
            path: 'facts-edit',
            pathMatch: 'full',
            component: FactsEditComponent,
          },
          {
            path: 'goals',
            pathMatch: 'full',
            component: GoalsComponent,
          },
          {
            path: 'param-goals',
            pathMatch: 'full',
            component: ParamGoalsComponent,
          },
          {
            path: 'credits',
            pathMatch: 'full',
            component: CreditsComponent,
          },
          {
            path: 'shop',
            pathMatch: 'full',
            component: ShopComponent,
          },
          {
            path: 'survey',
            pathMatch: 'full',
            component: SurveyComponent,
          },
          {
            path: 'phases',
            pathMatch: 'full',
            component: PhasesComponent,
          }
        ]
      },
      {
        path: 'forum',
        pathMatch: 'full',
        component: ForumComponent
      },
      {
        path: 'forum/message/:id',
        pathMatch: 'full',
        component: ForumMessageComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
