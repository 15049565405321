<div class="wrapper">
  <div class="toolbar px-0">
    <h3 class="ml-0 pointer breadcrumb-back" [routerLink]="['/gamification/facts']" [queryParams]="{domain: domain}"><i
        class="fas fa-chevron-left"></i></h3>
    <h3 class="text-left">Facts for {{subparam_label|t}}</h3>
    <button class="btn btn-sm btn-warning btn-add text-white mr-0 px-2" (click)="add()">
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <div class="tablescroll">
    <div class="row table-header-row">
      <div class="col-md-1"></div>
      <div class="col-md-7 text-bold text-center">Facts</div>
      <div class="col-md-2 text-bold text-center">Image</div>
      <div class="col-md-2 text-bold text-center">Video</div>
    </div>
    <div class="row" *ngIf="dataSub && !dataSub.closed">
      <div class="blink text-center py-4">Loading...</div>
    </div>
    <div class="row" *ngIf="facts.length">
      <app-fact-edit-item class="w-100" *ngFor="let item of facts; let ind=index" [item]="item" [index]="ind+1"
        style="border-radius: 4px;"></app-fact-edit-item>
    </div>
    <div *ngIf="!(dataSub && !dataSub.closed) && !facts.length">
      <div class="text-center py-4">No Facts to display</div>
    </div>
  </div>
</div>